import React, { useEffect, useState } from 'react'
import jobapplication from '../assets/images/jobapplication.png';
import Wrapper from '../components/Wrapper';
import { Form, Input, Spin } from 'antd';
import { authStep1 } from '../features/auth/authService';
import { useDispatch, useSelector } from 'react-redux';
import { apiHitWithTempToken } from '../service/authService';
import { Notify } from '../blocks/dialogs/Notify';
import { setTempToken } from '../features/auth/authSlice';
import CheckedPng from '../assets/images/check_circle.png';

function JobApplication() {


    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loader, setLoader] = useState(false);
    const { user } = useSelector((state) => state.user);


    const generateTempToken = async () => {
        try {
            const res = await authStep1();
            if (res.status === 200) {
                dispatch(setTempToken(res?.data?.access_token));
            }

        } catch (error) { }
    }

    useEffect(() => {
        generateTempToken();
    }, [])


    let onSubmit = async (values) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("action", "job_application_form");
        formData.append("f_name", values.f_name);
        formData.append("l_name", values.l_name);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("linkedin", values.linkedin);
        formData.append("applied_position", values.applied_position);
        formData.append("attachment", values.attachment);
        let res = await apiHitWithTempToken(formData);
        if (res?.status === 200) {
            if (res?.data.status === 400) {
                setLoader(false);
                window.scrollTo(0, 10);
            }
        } else {
            setLoader(false);
            Notify("error", res?.data?.message ?? "Opps something wrong");
        }
    }

    return (
        <>
            <Wrapper>
                <div className='container-fluid app-form'>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12 job-form-img' style={{ padding: 0 }}>
                            <img src={jobapplication} />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='job-app-form'>
                                <Form form={form} onFinish={onSubmit} >
                                    <div className="demo-form">
                                        <div>
                                            <spna className="demo-desc">Career</spna> <br />
                                            <span className='demo-heading'>Job Application Form</span>
                                        </div>

                                        <div className='row mt-3'>
                                            <div className='col-sm-6'>
                                                <div>
                                                    <label>First Name<span className='text-danger'>*</span></label>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter the first name",
                                                            },
                                                        ]}
                                                        name="f_name"
                                                        className="input-container"
                                                    >
                                                        <Input type="text" className='input-box' />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-sm-6'>
                                                <div>
                                                    <label>Last Name<span className='text-danger'>*</span></label>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter the last name",
                                                            },
                                                        ]}
                                                        name="l_name"
                                                        className="input-container"
                                                    >
                                                        <Input type="text" className='input-box' />
                                                    </Form.Item>
                                                </div>
                                            </div>


                                            <div className='col-sm-6 mt-1'>
                                                <div >
                                                    <label>E-mail<span className='text-danger'>*</span></label>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter the email",
                                                            },
                                                        ]}
                                                        name="email"
                                                        className="input-container"
                                                    >
                                                        <Input type="email" className='input-box' />
                                                    </Form.Item>
                                                </div>
                                            </div>


                                            <div className='col-sm-6 mt-1'>
                                                <div>
                                                    <label>LinkedIn Profile</label>
                                                    <Form.Item
                                                        name="linkedin"
                                                        className="input-container"
                                                    >
                                                        <Input type="text" className='input-box' />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-sm-6'>
                                                <div>
                                                    <label>Phone No<span className='text-danger'>*</span></label>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter the LinkedIn Profile",
                                                            },
                                                        ]}
                                                        name="phone"
                                                        className="input-container"
                                                    >
                                                        <Input type="text" className='input-box' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>

                                            <div className='col-sm-6'>
                                                <div>
                                                    <label>Applied Position<span className='text-danger'>*</span></label>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter the Applied Position*",
                                                            },
                                                        ]}
                                                        name="applied_position"
                                                        className="input-container"
                                                    >
                                                        <Input type="text" className='input-box' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div>
                                                    <label>Resume<span className='text-danger'>*</span></label>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter the LinkedIn Profile",
                                                            },
                                                        ]}
                                                        name="attachment"
                                                        className="input-container"

                                                    >
                                                        <Input type="file" className='input-box' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        {loader ? <Spin /> : <button className="btn btn-primary " style={{ textTransform: "none" }}>Submit Application</button>}

                                        <div className='job-notifi mt-4 d-flex'>
                                            <div>
                                                <img src={CheckedPng} alt="checked"/>
                                            </div>
                                            <div>
                                                <p>Your application has been submitted successfully. Our team will reach out to you after reviewing the application.</p>
                                            </div>
                                        </div>

                                    </div>

                                </Form>
                            </div>

                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
}

export default JobApplication;