// serviceWorker.js

export const register = async () => {
  if ('serviceWorker' in navigator) {
    try {
      // Unregister existing service workers
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }

      // Register the new service worker
      const newRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      return { success: true, scope: newRegistration.scope };
    } catch (error) {
      return { success: false, error };
    }
  } else {
    return { success: false, error: 'Service workers not supported' };
  }
};
// export const register = () => {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//     .register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
  
//       if (error.message.includes('file not found')) {
//         console.error('Ensure firebase-messaging-sw.js is in the correct location.');
//       } else {
//         console.error('Unhandled error during registration:', error);
//       }
//     });
    
  //   navigator.serviceWorker
  // .register('/firebase-messaging-sw.js')
  // .then((registration) => {
  //   console.log('Service Worker registered with scope:', registration.scope);
  // })
  // .catch((error) => {
  //   console.error('Service Worker registration failed:', error);

  //   if (error.message.includes('file not found')) {
  //     console.error('Ensure firebase-messaging-sw.js is in the correct location.');
  //   } else {
  //     console.error('Unhandled error during registration:', error);
  //   }
  // });

 
