import { useCallback, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './style.scss'
import {
  MdRotate90DegreesCcw,
} from 'react-icons/md';
import { CloseOutlined } from '@ant-design/icons';
import { BiFullscreen, BiExitFullscreen, BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import { Button,Modal, Spin, Tooltip } from 'antd';
import { FaFileSignature } from 'react-icons/fa';


const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

const PdfViewer = ({ url,onClose,esignLink='' }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [pages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const [isFull, setIsFull] = useState(false);
  const [scale, setScale] = useState(2.2);
  const [rotate, setRotate] = useState(0);

  /* When document gets loaded successfully */
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    // setPageNumber(1);
  };

  const toggleFullscreen = () => {
    const elem = document.querySelector('#pdf-view');

    if (!document.fullscreenElement) {
      setIsFull(true);
      elem.requestFullscreen().catch((err) => {
        // eslint-disable-next-line no-alert
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      setIsFull(false);
      document.exitFullscreen();
    }
  };

  const zoomIn = useCallback(() => {
    setScale((prev) => prev + 0.2);
  }, []);

  const zoomOut = useCallback(() => {
    setScale((prev) => prev - 0.2);
  }, []);

  const rotateView = useCallback(() => {
    if (rotate <= 0) {
      setRotate(360 - 90);
    } else {
      setRotate((prev) => prev - 90);
    }
  }, [rotate]);

  const addSignature=()=>{
    window.open(esignLink,'_blank')
  }

  if (!url) return <></>;

  return (
    <Modal
    open
    // height="100%"
    onClose={onClose}
    // style={{ height: '100%', background: 'transparent', width:'100%' }}
    zIndex={99999}
    destroyOnClose
    width="100%"
    placement="top"
    bodyStyle={{ background: '#0e0c2a' }}
    headerStyle={{ padding: 10 }}
    className="pdf-viewer-modal"
    title={<div className='file-viewer-title-body'>
    <p className='mb-0 fab-font-weight-semi-bold'>Pdf Preview</p>
    <Button size='small' icon={<CloseOutlined className='fab-font-weight-bold cross-btn'/>} type="text" className='fab-mb-0' onClick={onClose}/>
  </div>}
  footer={false}
    closable={false}
    // contentWrapperStyle={{width:'100%'}}
  >
    <div className="" id="pdf-view">

      <Document
        // file={file}
        // source={{ header: { 'Access-Control-Allow-Origin': '*', }, }}
        file={{ url, withCredentials: false }}
        httpHeaders={{
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST",
          "Access-Control-Allow-Headers": "Origin, Content-Type",
           "Expose-Headers": "Origin, Content-Type, X-Auth-Token"
        }}
        onLoadSuccess={onDocumentLoadSuccess}
        className="client_pdf__container__document"
        loading={
          <Spin tip="Loading PDF ..." spinning className="fab-text-white" indicator={antIcon} />
        }
        rotate={rotate}
        options={{
          cors: false,
        }}
      >
        {Array.from(new Array(pages), (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className="client-page-canv"
            scale={scale}
            renderTextLayer={false}
          />
        ))}
 
      </Document>


        <div className="client-buttonc">
          <Tooltip title="Zoom out">
          <button type="button" onClick={zoomOut} disabled={scale <= 0.6} className="Pre">
            <BiZoomOut />
          </button>
          </Tooltip>
          <div className="client-pagec">
            <Tooltip title="Rotate">
            <button type="button" onClick={rotateView}>
              <MdRotate90DegreesCcw />
            </button>
            </Tooltip>
          </div>
         <Tooltip title="Zoom In">
         <button type="button" onClick={zoomIn} disabled={scale >= 2}>
            <BiZoomIn />
          </button>
         </Tooltip>
         <button onClick={toggleFullscreen} className="client-toggle-btn" type="button">
        {isFull ? <BiExitFullscreen /> : <BiFullscreen />}
      </button>
    {esignLink &&  
  
             <button onClick={addSignature} className="add-e-sign-btn" type="button">
                Add Signature
            <FaFileSignature
              className="hover-text-primary cursor-pointer ml-1"
              size={22}
              // onClick={() => window.open(row?.careplansignlink, "_blank")}
              onClick={addSignature}
              />
              </button>
       

}
        </div>

    </div>
    </Modal>
  );
};


export default PdfViewer;
