

import React, { useEffect, useState } from 'react'
import { authStep1, authStep3 } from "../../features/auth/authService";

import PublicNavBar from "../../components/header/PublicNavBar";

import { useLocation } from 'react-router-dom';

import Banner from "../../components/UI/LandingPage/Banner";
import Coordination from "../../components/UI/LandingPage/Coordination";
import HealthCare from "../../components/UI/LandingPage/HealthCare";
import HealthSystem from "../../components/UI/LandingPage/HealthSystem";
import PatientAtHome from "../../components/UI/LandingPage/PatientAtHome";
import Quality from "../../components/UI/LandingPage/Quality";
import Footer from '../../components/common/Footer'
import {  useNavigate } from "react-router-dom";

import Testimonials from "../../components/UI/LandingPage/Testimonials";
import Stats from "../../components/UI/LandingPage/Stats";
import { ReactSession } from 'react-client-session';
import { useDispatch } from "react-redux";
import {
  setToken,

} from "../../features/auth/authSlice";
const LandingPage = () => {
 
  const navigate = useNavigate();



  //  var logindata ;
   var sessionToken ;
   const dispatch = useDispatch();

   const [loading, setLoading] = useState(true);



   
   const generateTempToken = async (userId) => {
   
    try {
      dispatch(
        setToken({ token: userId, isAuth: true })
      );
      navigate("/home");

      // const res = await authStep3(userId);
                
      // if (res.status === 200) {
      //   setLoading(false);
      //   const secureToken = await authStep1(res.data);
      //   dispatch(
      //     setToken({ token: userId, isAuth: true })
      //   );
   
      //   navigate("/home");
         
      // }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    ReactSession.setStoreType("localStorage");
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sessionToken = ReactSession.get("sessionTokenMedsetAuth");


   
      
    if(sessionToken && sessionToken !== '' && sessionToken != null){
    //  console.log('hit temp token with id',sessionToken)
      generateTempToken(sessionToken)
    }else{
      setLoading(false);
    }
}, [])
    
  return (
      
     <div>
        <PublicNavBar />
        <Banner />
        <HealthCare/>
        <HealthSystem />
        <Coordination/>
        <PatientAtHome/>
        <Quality/>
        <Stats/>
        <Testimonials/>
        <Footer/>

      </div>

  )};


export default LandingPage;
