import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { CaseMangerWrapper } from "../../../components/Wrapper";
import { getPatientListAsync } from "../../../features/case-manager/caseManagerSlice";
import Lottie  from "lottie-react";
import lottieload from "../../../../src/assets/images/lottieload.json";

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import {
  addPatientAddressAction,
  addPatientinsuracneAction,
  getPastPatientAppointments,
  getPatientAppointments,
  getPatientDetail,
  getShippingAddressAction,
} from "../../../features/auth/authSlice";
import _ from "lodash";
import moment from "moment";
import { Notify } from "../../../blocks/dialogs/Notify";
import {
  APIHitCall,
  createPatientService,
} from "../../../features/case-manager/caseManagerService";
import { apihitCall } from "../../../service/authService";
import { Loader } from "../../../blocks/loaders/Loader";
import { setMainPatientTab } from "../../../store/patient/Slice";
import { patientTabs } from "../../../utils/constants";
import { BiSearch, BiSend } from "react-icons/bi";
import { debounce } from "../../../utils/functions";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineEditNote } from "react-icons/md";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiRobotLine } from "react-icons/ri";
import { isMobile } from "react-device-detect";
import { AiSummaryModal } from "./AiSummaryModal";
import NotesChat from "./NotesChat";
const axios = require('axios');

const TabComponent = () => {
  const navigation = useNavigate();
  const params = useParams();
  return (
    <div className="profile-tabs patient-tab-menu">
      <span onClick={() => navigation(-1)} className="tab-back">
        <img src="assets/images/back-arrow.svg" alt={"Back"} />
      </span>
      <ul className="flex align-items-center">
        <li
          onClick={() =>
            navigation(`/case-manager/add-patient-info/${params?.userId}`)
          }
        >
          <a>Personal Information</a>
        </li>
        <li
          onClick={() =>
            navigation(`/case-manager/appointment-list/${params?.userId}`)
          }
        >
          <a>Appointment List</a>
        </li>
        <li
          onClick={() =>
            navigation(`/case-manager/appointment-list/${params?.userId}`)
          }
        >
          <a>Medical Records</a>
        </li>
      </ul>
    </div>
  );
};

export const PatientInfoSideBar = () => {
  const params = useParams();
  const { patientDetail } = useSelector(({ user }) => user);
  return (
    <div className="settings-left-menu patient-sub-menu flex">
      <div className="patient-name-box">
        <div className="patient-name-box-img">
          <img
            src={
              patientDetail?.[params?.userId]?.userimages?.image1 ||
              "assets/images/face-img.png"
            }
            alt=""
          />
        </div>
        <div className="patient-name-box-text">
          {patientDetail?.[params?.userId]?.Username}
        </div>
      </div>
      <ul>
        <li>
          <Link
            to={`/case-manager/add-patient-info${
              params?.userId ? `/${params?.userId}` : ""
            }`}
            className="flex"
          >
            Patient Info{" "}
            <span className="material-symbols-outlined">
              keyboard_arrow_right
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={`/case-manager/add-patient-address${
              params?.userId ? `/${params?.userId}` : ""
            }`}
            className="flex"
          >
            Patient address{" "}
            <span className="material-symbols-outlined">
              keyboard_arrow_right
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={`/case-manager/add-patient-insurance${
              params?.userId ? `/${params?.userId}` : ""
            }`}
            className="flex"
          >
            Patient Insurance{" "}
            <span className="material-symbols-outlined">
              keyboard_arrow_right
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export const PatientAppointmentList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedItem, setSelectedItem] = useState(0);
  const { patientDetail, patientAppointmentlist } = useSelector(
    ({ user }) => user
  );
  useEffect(() => {
    dispatch(getPatientDetail(params?.userId));
    dispatch(getPatientAppointments(params?.userId));
    dispatch(getPastPatientAppointments(params?.userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const splitTimeSlot = (timeSlot) => {
    let x = timeSlot.split("-");
    return x[x.length - 1];
  };
  const renderItem = (item, index) => {
    console.debug(item);
    let time = splitTimeSlot(item?.ordertimeslot);
    let scheduleDate = item.schedule_datetime?.split(" ");
    let date = moment(scheduleDate[0], "YYYY-MM-DD").format("DD MMM YYYY");
    let labourTimeSlot = "";

    if (item.labour_timslot !== "") {
      let t = item.labour_timslot.split(" ");
      labourTimeSlot = t[1] + " " + t[2] + " - " + t[4] + " " + t[5];
    }

    let orderStatus = "";
    let textColor = "";

    if (item.job_status == "0") {
      orderStatus = "Pending";
      //textColor = '#F05656';
      textColor = "#EC7F78";
    } else if (item.job_status == "2") {
      orderStatus = "Confirmed";
      textColor = "#00BEDA";
    } else if (item.job_status == "4") {
      orderStatus = "In Progress";
      textColor = "#00BEDA";
    } else if (item.job_status == "6") {
      orderStatus = "Completed";
      textColor = "#55B670";
    } else if (item.job_status == "3") {
      orderStatus = "Cancelled";
      textColor = "#EC7F78";
    }

    return (
      <li>
        <div className="patient-appointment-list-head">
          <div className="">
            <span className="material-symbols-outlined">calendar_today</span>{" "}
            {date}
          </div>
          <div className="">
            <span className="material-symbols-outlined">schedule</span> {time}
          </div>
        </div>
        <div className="patient-appointment-list-doctor">
          <div className="patient-appointment-doctor-box">
            <div className="patient-appointment-doctor-img">
              <img
                src={
                  item.subcategoryImage != ""
                    ? item.subcategoryImage
                    : "assets/images/nurse-img.png"
                }
                alt={"Nurse"}
              />{" "}
            </div>
            <div className="patient-appointment-doctor-name">
              {item.services}
            </div>
          </div>
          <div className="patient-appointment-list-status">
            <span className="appointment-status-green">{orderStatus}</span>
          </div>
        </div>
        <div className="patient-appointment-list-patient">
          <img
            src={
              item.profilepic != ""
                ? item.profilepic
                : "assets/images/patient-img.png"
            }
            alt={"Patient"}
          />
          {`${item.first_name} ${item.last_name}`}
        </div>
        <div className="patient-appointment-list-action">
          <button
            onClick={() => setSelectedItem(index)}
            className="btn btn-outline"
          >
            View Details
          </button>
        </div>
      </li>
    );
  };

  const selectedDetailItem = (item, index) => {
    let time = "";
    if (item?.ordertimeslot !== undefined) {
      time = splitTimeSlot(item?.ordertimeslot);
    }
    let scheduleDate = "";
    if (item.schedule_datetime !== undefined) {
      scheduleDate = item.schedule_datetime?.split(" ");
    }
    let date = moment(scheduleDate[0], "YYYY-MM-DD").format("DD MMM YYYY");
    let labourTimeSlot = "";

    if (!["", undefined].includes(item.labour_timslot)) {
      let t = item.labour_timslot.split(" ");
      labourTimeSlot = t[1] + " " + t[2] + " - " + t[4] + " " + t[5];
    }

    let orderStatus = "";
    let textColor = "";

    if (item.job_status == "0") {
      orderStatus = "Pending";
      //textColor = '#F05656';
      textColor = "#EC7F78";
    } else if (item.job_status == "2") {
      orderStatus = "Confirmed";
      textColor = "#00BEDA";
    } else if (item.job_status == "4") {
      orderStatus = "In Progress";
      textColor = "#00BEDA";
    } else if (item.job_status == "6") {
      orderStatus = "Completed";
      textColor = "#55B670";
    } else if (item.job_status == "3") {
      orderStatus = "Cancelled";
      textColor = "#EC7F78";
    }

    return (
      <div className="patient-appointment-detail">
        <div className="manager-summery-option">
          <div className="manager-summery-status">
            <span className="appointment-status-green">{orderStatus}</span>
          </div>
        </div>
        <div className="manager-summery-option">
          <div className="manager-summery-data">
            <div className="manager-summery-data-icon">
              <span className="material-symbols-outlined">
                medical_services
              </span>
            </div>
            <div className="manager-summery-data-text">
              Service <span>{item.services}</span>
            </div>
          </div>
        </div>
        <div className="manager-summery-option">
          <div className="manager-summery-data">
            <div className="manager-summery-data-icon">
              <span className="material-symbols-outlined">account_circle</span>
            </div>
            <div className="manager-summery-data-text">
              {`${item.first_name} ${item.last_name}`}{" "}
              <span>{item?.gender}</span>
            </div>
          </div>
        </div>

        <div className="manager-summery-option">
          <div className="manager-summery-data">
            <div className="manager-summery-data-icon">
              <span className="material-symbols-outlined">calendar_today</span>
            </div>
            <div className="manager-summery-data-text">
              Date <span>{date}</span>
            </div>
          </div>
        </div>

        <div className="manager-summery-option">
          <div className="manager-summery-data">
            <div className="manager-summery-data-icon">
              <span className="material-symbols-outlined">schedule</span>
            </div>
            <div className="manager-summery-data-text">
              Time <span>{time}</span>
            </div>
          </div>
        </div>

        <div className="manager-summery-option">
          <div className="manager-summery-data">
            <div className="manager-summery-data-icon">
              <span className="material-symbols-outlined">home</span>
            </div>
            <div className="manager-summery-data-text">
              Address <span>{item?.address}</span>
            </div>
          </div>
        </div>

        <div className="manager-summery-option">
          <div className="manager-summery-data">
            <div className="manager-summery-data-icon">
              <span className="material-symbols-outlined">call</span>
            </div>
            <div className="manager-summery-data-text">
              Phone number <span>{item?.mobile}</span>
            </div>
          </div>
        </div>
        <div className="appointment-price-detail">
          <div className="boking-total-price">
            <ul>
              <li>
                <p className="flex align-items-center">
                  Appointment <span>${item?.taskPrice}</span>
                </p>
              </li>
            </ul>

            <div className="total-amount flex">
              <p className="flex align-items-center">
                Amount payable <span>${item?.taskPrice}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <CaseMangerWrapper>
      <section className="booking-process">
        <div className="container">
          <div className="profile-main-box">
            {patientDetail?.[params?.userId]?.Username ? (
              <>
                <TabComponent />
                {/* tab component */}
                <div className="tabs-info-con settings-tab-info flex">
                  <div className="settings-left-menu patient-sub-menu flex">
                    <div className="patient-name-box">
                      <div className="patient-name-box-img">
                        <img
                          src={
                            patientDetail?.[params?.userId]?.userimages
                              ?.image1 || "assets/images/face-img.png"
                          }
                          alt=""
                        />
                      </div>
                      <div className="patient-name-box-text">
                        {patientDetail?.[params?.userId]?.Username}
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a className="flex active">
                          Upcoming{" "}
                          <span className="material-symbols-outlined">
                            keyboard_arrow_right
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="flex">
                          Past{" "}
                          <span className="material-symbols-outlined">
                            keyboard_arrow_right
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="settings-right-area patient-appointment-box">
                    <div className="patient-appointment-list">
                      <ul>
                        {(patientAppointmentlist?.[params?.userId] ?? []).map(
                          renderItem
                        )}
                      </ul>
                    </div>
                    {selectedDetailItem(
                      patientAppointmentlist[params?.userId] ?? [],
                      selectedItem
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "10%",
                }}
              >
                <Spin />
              </div>
            )}
          </div>
        </div>
      </section>
    </CaseMangerWrapper>
  );
};

export const AddPatientinfo = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const navigation = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const { patientDetail, token } = useSelector(({ user }) => user);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    form.setFieldsValue({
      email: patientDetail?.[params?.userId]?.Email,
      dob: patientDetail?.[params?.userId]?.dob,
      first_name: patientDetail?.[params?.userId]?.Username,
      lastname: patientDetail?.[params?.userId]?.l_name,
      mobile: patientDetail?.[params?.userId]?.Phone,
      image: patientDetail?.[params?.userId]?.image,
    });
  }, []);

  const onFinishForm = async (formItem) => {
    setLoading(true);

    formItem.image = image;
    const formData = new FormData();
    Object.keys(formItem).forEach((i) => {
      formData.append(i, formItem[i]);
    });
    formData.append("action", "registerPatient");
    formData.append("casemanager", user?.id);
    formData.append("auterizetokenid", user?.id);
    formData.append("societyId", user?.society_id);
    let res = await createPatientService(formData, token);

    if (res.data.status == 200) {
      Notify("success", res.data.message);
      setLoading(false);
    } else {
      Notify("error", res.data.message);
      setLoading(false);
    }
  };

  return (
    <CaseMangerWrapper>
      <section className="booking-process">
        <div className="container">
          <div className="profile-main-box">
            <div className="profile-tabs patient-tab-menu">
              <span onClick={() => navigation(-1)} className="tab-back">
                <img src="assets/images/back-arrow.svg" alt={"Back"} />
              </span>
              <ul className="flex align-items-center">
                <li
                  onClick={() =>
                    navigation(
                      `/case-manager/add-patient-info/${params?.userId}`
                    )
                  }
                >
                  <a>New Patients</a>
                </li>
              </ul>
            </div>

            <div className="tabs-info-con settings-tab-info flex">
              {/* <PatientInfoSideBar/> */}
              <Form
                onFinish={onFinishForm}
                form={form}
                style={{ width: "100%" }}
              >
                <div className="settings-right-area">
                  <div className="profile-main-info flex">
                    <div className="profile-photo flex align-items-center">
                      <div className="profile-img-info">
                        <label for="image">
                          <input
                            type="file"
                            onChange={(event) => {
                              setImage(event.target.files[0]);
                            }}
                            name="image"
                            id="image"
                            style={{ display: "none" }}
                          />
                          <img
                            src={
                              image
                                ? URL.createObjectURL(image)
                                : patientDetail?.[params?.userId]?.userimages
                                    ?.image1 || "assets/images/face-img.png"
                            }
                            alt=""
                            placeholder="Add Image"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="profile-fields-row flex no-border">
                    <div className="form-field">
                      <label>First name</label>
                      <Form.Item name="name">
                        <input
                          style={{ width: "100%", display: "flex" }}
                          type="text"
                        />
                      </Form.Item>
                    </div>

                    <div className="form-field">
                      <label>Last name</label>
                      <Form.Item name="last_name">
                        <input
                          style={{ width: "100%", display: "flex" }}
                          type="text"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-field">
                      <label>Date of birth</label>
                      <Form.Item name="dob">
                        <input
                          style={{ width: "100%", display: "flex" }}
                          type="date"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-field">
                      <label>Gender</label>
                      <div className="gender-select">
                        <label>
                          <input type="radio" />
                          Male
                        </label>

                        <label>
                          <input type="radio" />
                          Female
                        </label>

                        <label>
                          <input type="radio" />
                          Other
                        </label>
                      </div>
                    </div>
                    <div className="form-field">
                      <label>Mobile number</label>
                      <Form.Item name="phone">
                        <input
                          style={{ width: "100%", display: "flex" }}
                          type="text"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-field">
                      <label>Email address</label>
                      <Form.Item name="email">
                        <input
                          style={{ width: "100%", display: "flex" }}
                          type="email"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="booking-action slots-button">
                    {loading ? (
                      <Loader />
                    ) : (
                      <button className="btn btn-primary">Save</button>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </CaseMangerWrapper>
  );
};

export const AddPatientAddress = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();

  const { patientDetail } = useSelector(({ user }) => user);

  const [currentTab, setCurrentTab] = useState(1);
  const { patientShippingAddress } = useSelector(
    ({ user, caseManagerSlice }) => ({
      ...user,
      ...caseManagerSlice,
    })
  );

  useEffect(() => {
    dispatch(getShippingAddressAction(params.userId));
  }, []);

  const onFinshForm = async (formValue) => {
    const currentPatient = patientDetail?.[params?.userId];
    const payload = {
      ...formValue,
      lat: "0",
      lng: "0",
      email: currentPatient.Email,
      mobile: currentPatient?.Phone,
      full_name: currentPatient?.Username,
      user_id: currentPatient?.user_id,
      country_id: "US",
      Isdefault: "0",
      address: `${formValue?.street_name},${formValue?.house_number}`,
    };
    await dispatch(addPatientAddressAction(payload));
    dispatch(getShippingAddressAction(params.userId));
    setCurrentTab(1);
  };
  return (
    <CaseMangerWrapper>
      <section className="booking-process">
        <div className="container">
          <div className="profile-main-box">
            <TabComponent />

            <div className="tabs-info-con settings-tab-info flex">
              <PatientInfoSideBar />

              {currentTab === 1 ? (
                <>
                  <div className="patient-detail-form">
                    <div className="steps-container">
                      <div className="tabs-info-con patient-address-tab your-address-tab">
                        {_.isArray(patientShippingAddress) ? (
                          <ul>
                            {(patientShippingAddress ?? []).map((i) => {
                              return (
                                <li className="added-address flex">
                                  <div className="address-icon">
                                    <span className="material-symbols-outlined">
                                      {i?.addressType}
                                    </span>
                                  </div>
                                  <div className="address-info">
                                    <p>Home</p>
                                    <span>
                                      {i?.house_number || "-"}
                                      {i?.address}, {i?.street_name || "-"}-
                                      {i?.pincode || "-"},{i?.city || "-"},{" "}
                                      {i?.state || "-"}
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          <div>
                            <p>{patientShippingAddress}</p>
                          </div>
                        )}
                        <div>
                          <Button onClick={() => setCurrentTab(2)}>
                            Add new address
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Form
                    form={form}
                    onFinish={onFinshForm}
                    style={{ width: "100%" }}
                  >
                    <div className="settings-right-area">
                      <div className="profile-fields-row flex no-border">
                        <div className="form-field">
                          <label>Street Address</label>
                          <Form.Item name="street_name">
                            <input style={{ width: "100%" }} type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>Apt, suit, floor</label>
                          <Form.Item name={"house_number"}>
                            <input style={{ width: "100%" }} type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>City</label>
                          <Form.Item name={"city"}>
                            <input style={{ width: "100%" }} type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>State</label>
                          <Form.Item name={"state"}>
                            <input style={{ width: "100%" }} type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>Zipcode</label>
                          <Form.Item name={"pincode"}>
                            <input style={{ width: "100%" }} type="text" />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>Address Type</label>
                          <Form.Item name="addressType">
                            <Radio.Group>
                              <ul className="flex align-items-center">
                                <Radio value="home">
                                  <li className="flex align-items-center">
                                    Home
                                  </li>
                                </Radio>
                                <Radio value="work">
                                  <li className="flex align-items-center">
                                    Office
                                  </li>
                                </Radio>
                                <Radio value="public">
                                  <li className="flex align-items-center">
                                    Other
                                  </li>
                                </Radio>
                              </ul>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="booking-action slots-button">
                        <button
                          onClick={() => form.submit()}
                          className="btn btn-primary"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </CaseMangerWrapper>
  );
};

export const AddPatientInsurance = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [currentTab, setCurrenttab] = useState(1);
  const params = useParams();
  const [form] = Form.useForm();
  const { patientDetail } = useSelector(({ user }) => user);
  const [currentPatient] = useState(
    patientDetail?.[params.userId]?.insurancedetails
  );

  const onFrontImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
    }
  };

  const onFinish = async (formValue) => {
    await dispatch(
      addPatientinsuracneAction({
        ...formValue,
        user_id: params.userId,
        userId: params.userId,
      })
    );
  };

  return (
    <CaseMangerWrapper>
      <section className="booking-process">
        <div className="container">
          <div className="profile-main-box">
            <TabComponent />
            <div className="tabs-info-con settings-tab-info flex">
              <PatientInfoSideBar />
              {currentTab === 1 ? (
                <div className="settings-right-area">
                  <div className="profile-fields-row profile-fields-bot flex no-border">
                    <div className="form-field">
                      <label>Saved Insurance details</label>
                    </div>
                  </div>
                  {patientDetail?.[params.userId]?.insurancedetails?.f_name ? (
                    <Card style={{ width: "50%" }}>
                      <Row gutter={5}>
                        <Col className="gutter-row" span={7}>
                          <div>Name</div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                          <div>
                            {
                              patientDetail?.[params.userId]?.insurancedetails
                                ?.f_name
                            }
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={5}>
                        <Col className="gutter-row" span={7}>
                          <div>Insurance carrier</div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                          {
                            patientDetail?.[params.userId]?.insurancedetails
                              ?.account_number
                          }
                        </Col>
                      </Row>
                      <Row gutter={5}>
                        <Col className="gutter-row" span={7}>
                          <div>Member ID</div>
                        </Col>
                        <Col className="gutter-row" span={8}>
                          {
                            patientDetail?.[params.userId]?.insurancedetails
                              ?.memberId
                          }
                        </Col>
                      </Row>
                    </Card>
                  ) : (
                    <>
                      <Divider />
                      <Button onClick={() => setCurrenttab(2)}>
                        ADD New Insurance
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <div style={{ marginLeft: 30 }} className="patient-detail-form">
                  <Form form={form} onFinish={onFinish}>
                    <div className="steps-container">
                      <div className="patient-form">
                        <div className="form-field">
                          <label>First name</label>
                          <Form.Item name="f_name">
                            <input
                              value={currentPatient?.f_name}
                              type="text"
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>Last name</label>
                          <Form.Item name="l_name">
                            <input
                              value={currentPatient?.l_name}
                              type="text"
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>Date of birth</label>
                          <Form.Item name={"dob"}>
                            <input
                              type="date"
                              value={currentPatient?.dob}
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-field">
                          <label>Member ID</label>
                          <Form.Item name={"memberId"}>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                          <div className="note-grey-small">
                            Do not include numbers after dash in ID (e.g.-
                            123456-00)
                          </div>
                        </div>
                        <div className="form-field">
                          <label>Group/account Number</label>
                          <Form.Item name={"account_number"}>
                            <input
                              type="text"
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className="form-field">
                          <label>Upload insurance card image</label>
                          <div className="insurance-card-upload">
                            <input type={"file"} onChange={onFrontImage} />
                          </div>
                        </div>

                        <div className="booking-action">
                          <button
                            onClick={() => form.submit()}
                            className="btn btn-primary full-width"
                          >
                            Save & Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </CaseMangerWrapper>
  );
};


const PhoneNumberFormatter = ({ phoneNumber }) => {
  // Function to format the phone number
  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the cleaned number is of correct length
    if (cleaned.length === 10) {
      // Format as (XXX) XXX-XXXX
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }

    // Return the original if it doesn't match the expected length
    return phoneNumber; // or return an error message like "Invalid phone number"
  };

  return (
    <div>
      <p>{formatPhoneNumber(phoneNumber)}</p>
    </div>
  );
};
const ShowPatientModal = ({ item, onCancel }) => {
  return (
    <Modal footer={false} onCancel={onCancel} visible>
      <div>
        <span>adasd</span>
      </div>
    </Modal>
  );
};


const PatientList = () => {
  const { user } = useSelector(({ user }) => ({
    ...user,
  }));
  const columns = [
    {
      name: "#",

      selector: (row) => (
        <>
          {row.patientkey !== undefined &&
          row.patientkey !== null &&
          row.patientkey !== "0"
            ? row.patientkey
            : row.patientid}
        </>
      ),
    },

    {
      name: "Visit Id",

      selector: (row) => (
        <>
          {row.last_visitID != null && row.last_visitID !== undefined
            ? row.last_visitID
            : "-"}
        </>
      ),
    },
    {
      name: "Name",

      selector: (row) => <>{`${row.f_name}  ${row.l_name}`}</>,
    },
    {
      name: "DOB",

      selector: (row) => <>{!row.dob ? "N/A" : row.dob}</>,
    },
    {
      name: "Phone Number",

      selector: (row) => <>{row.phone == "0" ? "N/A" : <PhoneNumberFormatter phoneNumber={row?.phone}/> || "N/A"}</>,
    },
    {
      name: "Email",
      selector: (row) => <>{row?.email || "N/A"}</>,
    },

    {
      name: "Action",
      width: "100px",
      cell: (row) => (
        <div style={{ display: "flex", width: 60, justifyContent: "center" }}>
          {row.insuranceId &&
          row.insuranceId !== 0 &&
          row.insuranceId !== "0" &&
          row.insuranceId !== null ? (
            <Tooltip title="Pre Auth">
              <MdOutlineEditNote
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() =>
                  navigation("/case-manager/preauth", {
                    state: { patientData: row },
                  })
                }
              />
            </Tooltip>
          ) : null}

          <Tooltip title="Send SF-12">
            <BiSend
              size={18}
              style={{ margin: "0 8px", cursor: "pointer" }}
              onClick={() => {
                showModal(row.patientid);
              }}
            />
          </Tooltip>
        </div>
      ),
    },

      {
      name: "AI",
      width: "60px",
      cell: (row) => (
        <Tooltip title="AI Summary">

<img
  src="../../../assets/images/ai.png"
  onClick={() => showAIModal(row)}
  style={{
    cursor: "pointer",
    width: "24px",
    height: "24px",
    filter: "invert(34%) sepia(72%) saturate(636%) hue-rotate(196deg) brightness(95%) contrast(86%)",
  }}
/>


  
          
        </Tooltip>
      ),
    },

    {
      name: "SF-12",

      cell: (row) => (
        <div>
          {row.sf12data.length === 0 ? (
            "N/A"
          ) : (
            <div
              onClick={() => {
                if (row.sf12data.pdflink) {
                  window.open(row.sf12data.pdflink, "_blank");
                }
              }}
              style={{
                cursor: "pointer",
                fontSize: "0.8rem",
                color: "#007bff", // Link color
              }}
            >
              <div>PCS: {row.sf12data.physical_score}</div>
              <div>MCS: {row.sf12data.mental_score}</div>
            </div>
          )}
        </div>
      ),
    },

   
  ];

  const navigation = useNavigate();
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [patientDetail, setPatientDetail] = useState({});
  const [totalRows, setTotalRows] = useState(100);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [modalDoctorVisible, setModalDoctorVisible] = useState(null);
  const [isAIModalVisible, setIsAIModalVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { patient } = useSelector(({ caseManagerSlice, user }) => ({
    ...caseManagerSlice,
    ...user,
  }));

  const showAIModal = (patient) => {
    setSelectedPatient(patient);
    setIsAIModalVisible(true);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setPage(page);
  };
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const getListData = (s) => {
    dispatch(getPatientListAsync(s));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceApicall = useCallback(
    debounce((s) => {
      dispatch(getPatientListAsync(s));
    }, 300),
    [dispatch]
  );
  useMemo(() => {
    const payload = {
      search: search,
      page: 0,
    };
    if (search === "" || search.length > 2) debounceApicall(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const payload = {
      search: search,
      page: (page - 1) * 50,
    };
    debounceApicall(payload);
  }, [page]);

  const showModal = (id) => {
    setModalDoctorVisible(id);
  };

  const closeModal = () => {
    setModalDoctorVisible(null);
  };

  return (
    <CaseMangerWrapper>
      {showModalDetail && (
        <ShowPatientModal
          onCancel={() => setShowModalDetail(false)}
          item={patientDetail}
        />
      )}

      <DoctorSelectModal visible={modalDoctorVisible} onClose={closeModal} />

      <section className="booking-process">
        <div
          className=""
          style={{ display: "flex", width: "100%", padding: "0 1%" }}
        >
          <div className="data-table-main-box">
            {isMobile ? (
              <div>
                <div
                  className="datatable-list-head-btn"
                  style={{ columnGap: "10px" }}
                >
                  <Input
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    suffix={<BiSearch />}
                    className="input-h-40"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="datatable-list-head-title"
                    style={{ fontSize: "15px" }}
                  >
                    Patients List({patient?.patientcount ?? 0})
                  </div>

                  <Button
                    icon={<AiOutlinePlusCircle size={18} className="mr-1" />}
                    onClick={() => {
                      navigation("/case-manager/patient/create");
                    }}
                    type="primary"
                    style={{ padding: "10px" }}
                  >
                    Add New Patient
                  </Button>
                </div>
              </div>
            ) : (
              <div className="datatable-list-head ">
                <div className="datatable-list-head-title">
                  Patients List({patient?.patientcount ?? 0})
                </div>
                <div
                  className="datatable-list-head-btn"
                  style={{ columnGap: "10px" }}
                >
                  <Input
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    suffix={<BiSearch />}
                    className="input-h-40"
                  />
                  <Button
                    icon={<AiOutlinePlusCircle size={18} className="mr-1" />}
                    onClick={() => {
                      navigation("/case-manager/patient/create");
                    }}
                    type="primary"
                  >
                    Add New Patient
                  </Button>
                </div>
              </div>
            )}

            {patient?.loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "10%",
                }}
              >
                <Spin />
              </div>
            ) : (
              <>
                {isMobile ? (
                  <>
                    {(patient?.data ?? []).map((row) => {
                      const formattedDob = moment(row.dob).format("MM-DD-YYYY");

                      return (
                        <div
                          className="card py-3 px-2 mt-3"
                          onClick={() => {
                            dispatch(
                              setMainPatientTab(
                                patientTabs.PERSONAL_INFORMATION
                              )
                            );
                            navigation(
                              `/case-manager/patient/detail/${row?.patientid}`
                            );
                          }}
                        >
                          <div
                            style={{ marginLeft: "5px" }}
                            className="container"
                          >
                            <h6 style={{ marginTop: "12px" }}>
                              {`${row.f_name}  ${row.l_name}`}
                            </h6>
                            {!row.dob ? (
                              <span>Date of Birth - N/A</span>
                            ) : (
                              <span>{`Date of Birth - ${formattedDob}`}</span>
                            )}

                            <br />
                            <span>{`Phone - ${
                              row.phone == "0" || row.phone == ""
                                ? "N/A"
                                : row.phone
                            }`}</span>
                            <br />
                            <span>{`Email - ${row.email || "N/A"}`}</span>
                            <br />
                            <span>{`Patient ID -  ${
                              row.patientkey !== undefined &&
                              row.patientkey !== null &&
                              row.patientkey !== "0"
                                ? row.patientkey
                                : row.patientid
                            }`}</span>
                            <br />
                            <span>{`Visit ID -  ${
                              row.last_visitID != null &&
                              row.last_visitID !== undefined
                                ? row.last_visitID
                                : "N/A"
                            }`}</span>
                            <div>
                              {row.sf12data.length === 0 ? (
                                ""
                              ) : (
                                <div
                                  onClick={() => {
                                    if (row.sf12data.pdflink) {
                                      window.open(
                                        row.sf12data.pdflink,
                                        "_blank"
                                      );
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "0.8rem",
                                    color: "#007bff", // Link color
                                  }}
                                >
                                  <div>PCS: {row.sf12data.physical_score}</div>
                                  <div>MCS: {row.sf12data.mental_score}</div>
                                </div>
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                //alignItems: "center",
                                cursor: "pointer",
                                marginTop: "8px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                // Show the modal
                                showModal(row.patientid);
                              }}
                            >
                              <BiSend size={18} />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "0.9rem",
                                  color: "#007bff",
                                }}
                              >
                                Send SF-12
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{ marginTop: "10px", minWidth: "100px" }}
                        onClick={() => setPage(page - 1)}
                        disabled={page <= 1} // Disable if it's the first page
                      >
                        Previous Page
                      </Button>

                      <Button
                        type="primary"
                        style={{ marginTop: "10px", minWidth: "100px" }}
                        onClick={() => setPage(page + 1)}
                        disabled={!(patient?.data?.length === rowsPerPage)} // Disable if it's the last page
                      >
                        Next Page
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="data-table-contain d-table patient-table ">
                    <DataTable
                      labelRowsPerPage=""
                      paginationRowsPerPageOptions={[50]}
                      pagination
                      paginationServer
                      paginationTotalRows={patient?.patientcount ?? 0}
                      paginationDefaultPage={page}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      paginationPerPage={rowsPerPage}
                      selectableRows={false}
                      progressPending={loading}
                      columns={columns}
                      data={patient?.data ?? []}
                      onRowClicked={(row) => {
                        dispatch(
                          setMainPatientTab(patientTabs.PERSONAL_INFORMATION)
                        );
                        navigation(
                          `/case-manager/patient/detail/${row?.patientid}`
                        );
                        //  }
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>

      {selectedPatient && isAIModalVisible && <AiSummaryModal
        isVisible={isAIModalVisible}
        onClose={() => {
          setIsAIModalVisible(false);
          setSelectedPatient(null)}}
        patientData={selectedPatient}
      />}
    </CaseMangerWrapper>
  );
};

export default PatientList;
const { Option } = Select;

const DoctorSelectModal = ({ visible, onClose }) => {
  const { user } = useSelector(({ user }) => ({
    ...user,
  }));
  const [doctorData, setDoctorData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (visible) {
      getDoctorListApi();
    }
  }, [visible, search]);

  const getDoctorListApi = async () => {
    try {
      setLoading(true);
      const FD = new FormData();
      FD.append("action", "filterred_doctor_list");
      FD.append("societyid", user?.society_id);
      FD.append("keyword", search);
      FD.append("auterizetokenid", user?.id);

      const res = await APIHitCall(FD);

      if (res?.data?.status === 200) {
        setDoctorData(res?.data?.result);
      }
    } catch (error) {
      setDoctorData([]);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendsf12FormApi = async () => {
    try {
      setLoading(true);
      const FD = new FormData();
      FD.append("action", "send_sf12_form");
      FD.append("clinicId", user?.society_id);
      FD.append("patientId", visible);
      FD.append("doctorId", selectedDoctor);
      FD.append("auterizetokenid", user?.id);

      const res = await APIHitCall(FD);

      if (res?.data?.status === 200) {
        message.success("Form sent successfully");
        onClose();
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedDoctor(value);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleSubmit = () => {
    // console.log("Selected Doctor ID:", selectedDoctor);
    // console.log("Selected patient ID:", visible);
    sendsf12FormApi();
  };

  return (
    <Modal
      visible={visible}
      title="Select Provider"
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={!selectedDoctor}
        >
          Send
        </Button>,
      ]}
    >
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Select a provider"
        optionFilterProp="children"
        onChange={handleSelectChange}
        onSearch={handleSearch}
        loading={loading}
      >
        {doctorData.map((item) => (
          <Option key={item.doctorid} value={item.doctorid}>
            {item.f_name} {item.l_name}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};




