import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";

const isFCMSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
 
};

let app = null;
let messaging = null;
if (isFCMSupported()) {
  app = initializeApp(firebaseConfig);
  // Messaging service
  messaging = getMessaging(app);
}

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
// Messaging service
//const messaging = getMessaging(app);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const RequestPermission = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        await  deleteToken(messaging);
        const token = await getToken(messaging, {
          vapidKey:
          process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
        resolve(token);
      } else if (permission === "denied") {
        
        if(isSafari){
          try{
          //  await  deleteToken(messaging);

          const token = await getToken(messaging, {
            vapidKey:
              process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });
          if(token){
            resolve(token);
          }else{
            resolve("");
          }
        }catch(err){
          resolve("");
        }

        }else
        resolve("");
      } else {
        resolve("");
      }
    } catch (error) {
      console.error(
        "An error occurred while requesting notification permission or getting the token.",
        error
      );
      resolve("");
    }
  });
};

//RequestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
