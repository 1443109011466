// import React from "react";
// import { createRoot } from "react-dom/client";
// import { Provider } from "react-redux";
// import { store } from "./app/store";
// import App from "./App";
// // import reportWebVitals from "./reportWebVitals";
// import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";
// import ChatPushNotification from "./components/ChatPushNotification";
// import { register } from "./serviceWorker";

// import './styles/main.scss'
// // import "node_modules/video-react/dist/video-react.css"; 
// import 'bootstrap/dist/css/bootstrap.min.css'


// import "antd/dist/antd.css";  // or 'antd/dist/antd.less'

// const container = document.getElementById("root");
// const root = createRoot(container);

// let persistor = persistStore(store);
// const isFCMSupported = () => {
//   return 'serviceWorker' in navigator && 'PushManager' in window;
// };

// root.render(
      
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//         {isFCMSupported()? <ChatPushNotification />:null}
//       </PersistGate>
//     </Provider>
// );

// if (isFCMSupported())
//   register().then((result) => {
//     if (result.success) {
//       console.log('Service worker registered successfully:', result.scope);
//     } else {
//       console.error('Service worker registration failed:', result.error);
//     }
//   });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ChatPushNotification from "./components/ChatPushNotification";
import { register } from "./serviceWorker";

import './styles/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
//import "antd/dist/antd.css";
import 'antd/dist/antd.min.css'

const container = document.getElementById("root");
const root = createRoot(container);

let persistor = persistStore(store);

const AppWrapper = () => {
  const [isServiceWorkerRegistered, setServiceWorkerRegistered] = useState(false);

  React.useEffect(() => {
    const isFCMSupported = () => {
      return 'serviceWorker' in navigator && 'PushManager' in window;
    };

    if (isFCMSupported()) {
      register().then((result) => {
        if (result.success) {
          setServiceWorkerRegistered(true); // Enable ChatPushNotification
        } else {
          console.error('Service worker registration failed:', result.error);
        }
      });
    } else {
      console.error('FCM is not supported on this browser.');
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        {isServiceWorkerRegistered && <ChatPushNotification />}
      </PersistGate>
    </Provider>
  );
};

root.render(<AppWrapper />);
