import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
  Tabs,
  Select,
} from "antd";

import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CaseMangerWrapper } from "../../components/Wrapper";
import { logout, logoutapi } from "../../features/auth/authSlice";
import { Notify } from "../../blocks/dialogs/Notify";
import { apihitCall } from "../../service/authService";
import { Loader } from "../../blocks/loaders/Loader";
import { ReactSession } from "react-client-session";
import { isMobile } from "react-device-detect";
import "./style.css";
import { APIHitCall } from "../../features/case-manager/caseManagerService";
import { get, set } from "lodash";
import "./PushNotification.css";

export const ProfileDetailCaseManagerFeedback = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(1);
  const { token } = useSelector(({ user }) => user);
  const { user } = useSelector((state) => state?.user);

  const logoutUser = async () => {
    await logutApi();
    ReactSession.setStoreType("localStorage");
    ReactSession.set("sessionTokenMedsetAuth", "");

    dispatch(logout());
    navigate("/home");
    message.success("Logout success");
  };
  const logutApi = async () => {
    const formData = new FormData();

    formData.append("action", "userLogout");

    formData.append("country", "US");
    formData.append("auterizetokenid", user?.id);
    formData.append("id", user?.id);

    let res = await apihitCall(formData, token);
  };

  const renderTab = () => {
    switch (currentTab) {
      case 1:
        return <ProfileDetailCaseManager />;
      case 2:
        return <ChangePassWord onCancel={() => setCurrentTab(1)} />;
      case 3:
        return <ContactUS />;
      case 4:
        return <Admin />;

      case 5:
        return <PushNotification />;
      default:
        return null;
    }
  };
  return (
    <CaseMangerWrapper>
      <section className="pt-5">
        <div className="container">
          <div className="profile-main-box">
            <div className="d-flex justify-content-between position-relative align-items-center">
              <Tabs
                defaultActiveKey={currentTab}
                items={[
                  {
                    label: "Profile",
                    key: 1,
                  },
                  {
                    label: "Change Password",
                    key: 2,
                  },
                  {
                    label: "Help & feedback",
                    key: 3,
                  },
                  {
                    label: "Admin",
                    key: 4,
                  },
                  {
                    label: "Push Notification",
                    key: 5,
                  },
                ]}
                className="w-100 profile-tab-bar"
                onChange={setCurrentTab}
                tabBarStyle={{
                  paddingLeft: isMobile ? 30 : 70,
                  height: 63,
                }}
              />
              {isMobile ?  <Button
                  onClick={logoutUser}
                  type="link "
                  style={{ right: 10, top: 13 ,marginTop:"50px"}}
                  className="text-danger position-absolute d-flex align-items-center"
                >
                  Logout <BiChevronRight />
                </Button> : (
                <Button
                  onClick={logoutUser}
                  type="link "
                  style={{ right: 10, top: 13 }}
                  className="text-danger position-absolute d-flex align-items-center"
                >
                  Logout <BiChevronRight />
                </Button>
              )}
            </div>

            {renderTab()}
          </div>
        </div>
      </section>
    </CaseMangerWrapper>
  );
};

export const ProfileDetailCaseManager = () => {
  const intialValues = {
    f_name: "",
    l_name: "",
    mobile_number: "",
    email: "",
  };
  const [form] = Form.useForm();

  const { user } = useSelector((state) => state?.user);

  useEffect(() => {
    console.log(user);
    form.setFieldsValue({
      f_name: user?.username|| "N/A",
      l_name: user?.lastname|| "N/A",
      email: user?.email|| "N/A",
      mobile_number: user?.phone || "N/A"
    });
  }, [user]);

  const handleSubmit = (item) => {};
  return (
    <div className="profile-wrap">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={intialValues}
      >
        <Row>
          <Col span={24} md={5}>
            <Avatar
              size={100}
              shape="circle"
              src="assets/images/profile-img.png"
            />
          </Col>
          <Col span={24} md={19}>
            <Row className="">
              <Col span={24} md={10}>
                <Form.Item name="f_name" label="First Name" className="">
                  <Input placeholder="first name" size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={2} />
              <Col span={24} md={10}>
                <Form.Item name="l_name" label="Last Name">
                  <Input placeholder="last name" size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={10}>
                <Form.Item
                  name="mobile_number"
                  label="Mobile number"
                  className=""
                >
                  <Input placeholder="Mobile number" size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={24} md={2} />
              <Col span={24} md={10}>
                <Form.Item name="email" label="Email address">
                  <Input placeholder="Email address" size="large" readOnly />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={24} md={22}>
                <Space className="d-flex" style={{ justifyContent: "start" }}>
                  <Button onClick={form.submit} type="primary">
                    SAVE CHANGES
                  </Button>
                </Space>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const ContactUS = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { token } = useSelector(({ user }) => user);
  const { user } = useSelector((state) => state.user);

  const formData = new FormData();
  const params = useParams();

  useEffect(() => {
    form.setFieldsValue({
      name: user?.username,
    });
  }, [user]);

  const handleSubmit = async (item) => {
    setLoading(true);
    formData.append("action", "contact_us");
    formData.append("name", item.name);
    formData.append("phone", item.phone);
    formData.append("message", item.message);
    formData.append("country", "US");
    formData.append("auterizetokenid", user?.id);
    formData.append("userId", user?.id);

    let res = await apihitCall(formData, token);

    if (res?.data?.status == 200) {
      Notify("success", res.data.message);
      setLoading(false);
    } else {
      Notify("error", res.data.message);
      setLoading(false);
    }
  };

  const initialState = {
    name: "",
    phone: "",
    message: "",
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialState}
      >
        <div className="p-5">
          <Row className="mt-3" gutter={20}>
            <Col span={24} md={8}>
              <Form.Item name="name" label="Name" className="">
                <Input size="large" />
              </Form.Item>
            </Col>

            <Col span={24} md={8}>
              <Form.Item name="phone" label="Mobile Number" className="">
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col span={24} md={16}>
              <div className="form-field">
                <Form.Item name="message" label="Message" className="">
                  <textarea
                    style={{ width: "100%" }}
                    type="text"
                    rows="4"
                    placeholder=""
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row className="mt-3" gutter={20}>
            <Col span={24} md={22}>
              <Space className="d-flex" style={{ justifyContent: "start" }}>
                {loading ? (
                  <Loader />
                ) : (
                  <Button onClick={form.submit} type="primary">
                    Submit
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </div>
      </Form>

      <div className="booking-action-textsupport ml-3">
        Or reach us on{" "}
        <span>
          <a href="tel:+14088877974">(408) 887-7974</a>
        </span>
      </div>
    </>
  );
};

const Admin = () => {
  const [formAppointment] = Form.useForm();
  const [formMedication] = Form.useForm();
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [loadingMedication, setLoadingMedication] = useState(false);

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    getTemplates();
  });

  async function submit(template, type, temId) {
    try {
      const FD = new FormData();
      FD.append("action", "post_notification_template");
      FD.append("clinicId", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      FD.append("template", template);
      FD.append("type", type);
      FD.append("temId", temId);

      const res = await APIHitCall(FD);
      setLoadingAppointment(false);
      setLoadingMedication(false);
      if (res?.data?.status === 200) {
      }
    } catch (error) {
      console.error(error);
    }
  }
  var medicationtemId = "";
  var appointmenttemId = "";

  async function getTemplates() {
    try {
      const FD = new FormData();
      FD.append("action", "list_notification_template");
      FD.append("clinicId", user?.society_id);
      FD.append("auterizetokenid", user?.id);

      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        let type1FirstObject = res.data.result.find(
          (item) => item.type === "1"
        );
        let type2FirstObject = res.data.result.find(
          (item) => item.type === "2"
        );
        // console.log( "1",type2FirstObject.template)

        if (type2FirstObject !== undefined) {
          appointmenttemId = type2FirstObject.temId;
          formAppointment.setFieldsValue({
            message: type2FirstObject.template,
          });
        }

        if (type1FirstObject !== undefined) {
          medicationtemId = type1FirstObject.temId;

          formMedication.setFieldsValue({
            message: type1FirstObject.template,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmitAppointment = async (item) => {
    setLoadingAppointment(true);
    submit(item.message, "2", appointmenttemId);
  };

  const handleSubmitMedication = async (item) => {
    setLoadingMedication(true);
    submit(item.message, "1", medicationtemId);
  };

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "10px 50px" }}>
        <h2 style={{ textAlign: "center", color: "#4a5568" }}>
          Reminder Templates
        </h2>
        <p style={{ textAlign: "center", color: "#718096" }}>
          Fill out the templates for appointment and medication reminders. Use
          placeholders for patient's first name, last name, and doctor's name.
        </p>

        <Form
          form={formAppointment}
          layout="vertical"
          onFinish={handleSubmitAppointment}
          style={{ marginBottom: "20px" }}
        >
          <h5 style={{ textAlign: "center", color: "#4a5568" }}>
            Appointment Reminder Template
          </h5>
          <Form.Item
            name="message"
            label="Use PT_FIRST_NAME, PT_LAST_NAME, and DOC_NAME as placeholders"
          >
            <Input.TextArea
              maxLength={150}
              rows={4}
              placeholder="Enter your appointment reminder template here..."
            />
          </Form.Item>

          <Button type="primary" block onClick={formAppointment.submit}>
            {loadingAppointment ? <Spin /> : "Submit Appointment Template"}
          </Button>
        </Form>

        <Form
          form={formMedication}
          layout="vertical"
          onFinish={handleSubmitMedication}
        >
          <h5 style={{ textAlign: "center", color: "#4a5568" }}>
            Medication Reminder Template
          </h5>
          <Form.Item
            name="message"
            label="Use PT_FIRST_NAME and PT_LAST_NAME as placeholders"
          >
            <Input.TextArea
              maxLength={150}
              rows={4}
              placeholder="Enter your medication reminder template here..."
            />
          </Form.Item>

          <Button type="primary" block onClick={formMedication.submit}>
            {loadingMedication ? <Spin /> : "Submit Medication Template"}
          </Button>
        </Form>
      </div>
    </>
  );
};

const ChangePassWord = ({ onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const { token } = useSelector(({ user }) => user);
  const { user } = useSelector((state) => state.user);

  const formData = new FormData();
  const params = useParams();

  const handleSubmit = async (item) => {
    if (item.newPassword != item.confirmPassword) {
      Notify("error", "New Password and Condirm Password does not match");
      return;
    }
    setLoading(true);

    formData.append("action", "change_password_updated");
    formData.append("oldPassword", item.oldPassword);
    formData.append("newPassword", item.newPassword);
    formData.append("auterizetokenid", user?.id);
    formData.append("userId", user?.id);

    let res = await apihitCall(formData, token);

    if (res.data.status == 200) {
      Notify("success", res.data.message);
      setLoading(false);
    } else {
      Notify("error", res.data.message);
      setLoading(false);
    }
  };

  const initialState = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={initialState}
    >
      <div className="p-5">
        <Row className="mt-3" gutter={20} md={10}>
          <Col span={24} md={8}>
            <Form.Item name="oldPassword" label="Old Psssword" className="">
              <Input
                style={{ borderRadius: "5px" }}
                placeholder="old password"
                size="small"
                type={oldPasswordVisible ? "text" : "password"}
                suffix={
                  <span
                    onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
                  >
                    {oldPasswordVisible ? (
                      <FaEye style={{ color: "#888888", fontSize: 24 }} />
                    ) : (
                      <FaEyeSlash style={{ color: "#888888", fontSize: 24 }} />
                    )}
                  </span>
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3" gutter={20}>
          <Col span={24} md={8}>
            <Form.Item name="newPassword" label="New Password" className="">
              <Input
                style={{ borderRadius: "5px" }}
                placeholder="new password"
                size="small"
                type={newPasswordVisible ? "text" : "password"}
                suffix={
                  <span
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  >
                    {newPasswordVisible ? (
                      <FaEye style={{ color: "#888888", fontSize: 24 }} />
                    ) : (
                      <FaEyeSlash style={{ color: "#888888", fontSize: 24 }} />
                    )}
                  </span>
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-3" gutter={20}>
          <Col span={24} md={8}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              className=""
            >
              <Input
                style={{ borderRadius: "5px" }}
                placeholder="confirm password"
                size="small"
                type={confirmPasswordVisible ? "text" : "password"}
                suffix={
                  <span
                    onClick={() =>
                      setConfirmPasswordVisible(!confirmPasswordVisible)
                    }
                  >
                    {confirmPasswordVisible ? (
                      <FaEye style={{ color: "#888888", fontSize: 24 }} />
                    ) : (
                      <FaEyeSlash style={{ color: "#888888", fontSize: 24 }} />
                    )}
                  </span>
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-3" gutter={20}>
          <Col span={24} md={22}>
            <Space className="d-flex" style={{ justifyContent: "start" }}>
              {loading ? (
                <Loader />
              ) : (
                <Button onClick={form.submit} type="primary">
                  UPDATE
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

const { Option } = Select;
const { TextArea } = Input;

const filters = ["Send Notification to Patient's by Doctor", "Disease"]; // Add your filters here

function PushNotification() {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedDisease, setSelectedDisease] = useState("");
  const [selectedSaveNoti, setSelectedSavedNoti] = useState("");

  const [messageNotify, setMessage] = useState("");
  const [videourl, setVideoUrl] = useState("");

  const [searchName, setSearchName] = useState("");
  const [savedNotiData, setdSavedNotiData] = useState([]);

  const [doctorData, setdoctorData] = useState([]);
  const [diseaseData, setdiseaseData] = useState([]);

  const { user } = useSelector(({ user }) => ({ ...user }));

  async function get_doctor_list() {
    try {
      const FD = new FormData();
      FD.append("action", "get_doctor_list");
      FD.append("societyid", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        setdoctorData(res?.data?.result);
      } else {
        setdoctorData([]);
      }
    } catch (error) {
      setdoctorData([]);
      console.error(error);
    }
  }

  async function get_saved_notification() {
    try {
      const FD = new FormData();
      FD.append("action", "get_saved_campaign");
      FD.append("clinicId", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      FD.append("diseaseId", selectedDisease);
      FD.append("doctorid", selectedDoctor);

      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        setdSavedNotiData(res?.data?.result);
      } else {
        setdSavedNotiData([]);
      }
    } catch (error) {
      setdSavedNotiData([]);
      console.error(error);
    }
  }

  async function get_disease_list() {
    try {
      const FD = new FormData();
      FD.append("action", "list_common_disease");
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        setdiseaseData(res?.data?.result);
      } else {
        setdiseaseData([]);
      }
    } catch (error) {
      setdiseaseData([]);
      console.error(error);
    }
  }

  async function saveNotification() {
    if (messageNotify === "") {
      return;
    }

    if (!selectedDisease && !selectedDoctor) {
      return;
    }
    try {
      const FD = new FormData();
      var dis_name = "";
      if (selectedDisease) {
        dis_name = diseaseData.find(
          (item) => item.diseaseId === selectedDisease
        );
      }

      FD.append("action", "save_notification_campaign");
      // FD.append("societyid", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      FD.append("casemanager", user?.id);
      FD.append("doctorid", selectedDoctor);

      FD.append("message", messageNotify);
      FD.append("clinicId", user?.society_id);
      FD.append("disease_name", dis_name?.disease_name ?? "");
      FD.append("diseaseId", selectedDisease);
      FD.append("file_url", videourl);
      FD.append("campaign_name", searchName);

    

      // const res = await APIHitCall(FD);
      // if (res?.data?.status === 200) {
      //   handleFilterChange("")
      //   message.success("Notification has been saved successfully")
      // }
    } catch (error) {
      console.error(error);
    }
  }

  async function sendNotification() {
    try {
      const FD = new FormData();

      if (!selectedSaveNoti) {
        return;
      }
      FD.append("action", "send_patient_notification");
      FD.append("societyid", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      FD.append("casemanager", user?.id);

      FD.append("clinicId", user?.society_id);

      FD.append("campaignId", selectedSaveNoti);

    

      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        handleFilterChange("");
        message.success("Notification has been saved successfully");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    get_doctor_list();
    get_disease_list();
  }, []);

  useEffect(() => {
    get_saved_notification();
  }, [selectedDisease, selectedDoctor]);

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    setSearchName("");
    setSelectedSavedNoti("");
    setSelectedDisease(""); // Reset disease selection when filter changes
    setSelectedDoctor(""); // Reset doctor selection when filter changes
    setMessage("");
    setVideoUrl(""); // Reset message when filter changes
  };

  const handleDoctorChange = (value) => {
    setSelectedDoctor(value);
  };

  const handleDiseaseChange = (value) => {
    setSelectedDisease(value);
    setVideoUrl("");
    setSearchName("");
    setSelectedSavedNoti("");
    setSelectedDoctor(""); // Reset doctor selection when filter changes
    setMessage(""); // Reset message when filter changes
  };
  const handleSavedNotiChange = (value) => {
    setSelectedSavedNoti(value);
    const data = savedNotiData.find(
      (searchName) => searchName.campaignId === value
    );
    if (data) {
      setMessage(data.message);
      setVideoUrl(data.file_url);
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleVideoUrlChange = (event) => {
    setVideoUrl(event.target.value);
  };
  const handleSearchNameChange = (event) => {
    setSearchName(event.target.value);
  };

  const handleSave = () => {
    // Logic to send notification
    if (messageNotify !== "") saveNotification();
  };

  const handleSendNotify = () => {
    // Logic to send notification
    if (messageNotify !== "") sendNotification();
  };

  function validateUrl() {
    if (!videourl) {
      return; // If videourl is empty, don't run the validation
    }

    const urlPattern = new RegExp(
      "^(https?://)?" + // protocol
        "([^\\s\\.]+\\.?)+[^\\s]*$",
      "i"
    ); // domain name or ip address
    if (!urlPattern.test(videourl)) {
      alert("Please enter a valid URL.");
    }
  }

  return (
    <div className="notification-container">
      <Space direction="vertical" style={{ width: "100%" }}>
        <label>Filter Selection:</label>
        <Select
          placeholder="Choose a filter"
          onChange={handleFilterChange}
          value={selectedFilter || undefined}
        >
          {filters.map((filter) => (
            <Option key={filter} value={filter}>
              {filter}
            </Option>
          ))}
        </Select>

        {selectedFilter === "Send Notification to Patient's by Doctor" && (
          <>
            <label>Doctor Selection:</label>
            <Select
              placeholder="Choose a doctor"
              onChange={handleDoctorChange}
              value={selectedDoctor || undefined}
            >
              {doctorData.map((item, index) => (
                <Option key={index} value={item?.doctorid}>
                  {`${item?.f_name} ${item?.l_name}`}
                </Option>
              ))}
            </Select>
          </>
        )}

        {selectedFilter === "Disease" && (
          <>
            <label>Disease Selection:</label>
            <Select
              placeholder="Choose a disease"
              onChange={handleDiseaseChange}
              value={selectedDisease || undefined}
            >
              {diseaseData.map((item, index) => (
                <Option key={index} value={item?.diseaseId}>
                  {`${item?.disease_name}`}
                </Option>
              ))}
            </Select>
          </>
        )}

        {savedNotiData.length > 0 && (selectedDoctor || selectedDisease) && (
          <>
            <label>Template Selection:</label>
            <Select
              placeholder="Choose a saved search"
              onChange={handleSavedNotiChange}
              value={selectedSaveNoti || undefined}
            >
              {savedNotiData.map((item, index) => (
                <Option key={index} value={item?.campaignId}>
                  {`${item?.campaign_name}`}
                </Option>
              ))}
            </Select>
          </>
        )}

        <label>Message:</label>
        <TextArea
          disabled={selectedSaveNoti !== ""}
          placeholder="Enter your message"
          onChange={handleMessageChange}
          value={messageNotify}
          rows={4}
        />

        <label>Video URL:</label>
        <TextArea
          disabled={selectedSaveNoti !== ""}
          placeholder="Enter or Paste video URL"
          onChange={handleVideoUrlChange}
          value={videourl}
          rows={2}
          onBlur={validateUrl}
        />

        {selectedSaveNoti === "" && (
          <>
            <label>Search Name:</label>
            <TextArea
              placeholder="Enter search name"
              onChange={handleSearchNameChange}
              value={searchName}
              rows={2}
            />
          </>
        )}

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          {selectedSaveNoti === "" && (
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          )}
          <div style={{ width: "10px" }} />
          {selectedSaveNoti !== "" && (
            <Button type="primary" onClick={handleSendNotify}>
              Send Notification
            </Button>
          )}
        </div>
      </Space>
    </div>
  );
}
