import React, { useState } from 'react';
import { Form, Input, Button, Select, Card, Space, Divider, Spin, message } from 'antd';
import { CaseMangerWrapper } from '../../Wrapper';
// import 'antd/dist/antd.css';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { APIHitCall } from '../../../features/case-manager/caseManagerService';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;
const { TextArea } = Input;

const SurveyForm = () => {
    const navigate = useNavigate();

    const [form, setForm] = useState({
        surveyName: '',
        description: '',
        question: [{ question: '', field: '', option: Array(4).fill(''),"answer":"" }]
    });
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e, index, optionIndex) => {
        const { name, value } = e.target;
        const list = [...form.question];
        if (name === 'option') {
            list[index].option[optionIndex] = value;
        } else {
            list[index][name] = value;
        }
        setForm(prevState => ({ ...prevState, question: list }));
    };

    const handleAddQuestion = () => {
        setForm(prevState => ({ ...prevState, question: [...prevState.question, { question: '', field: '', option: Array(4).fill('') }] }));
    };

    const handleSubmit = () => {
        submitSurvey();
    };
    const { user } = useSelector((state) => state.user);

    async function submitSurvey() {
        setLoading(true);
        const formData = new FormData();

        formData.append("action", "add_survey");
        formData.append("survery_name", form?.surveyName);
        formData.append("surveyquestion", JSON.stringify(form?.question));
        formData.append("description",  form?.description);

        formData.append("clinicId", user?.society_id)

        formData.append("casemanager",user?.id);

        formData.append("auterizetokenid", user?.id);
      
        let res = await APIHitCall(formData);
        setLoading(false);
     
        if (res.data.status === 200) {
            message.success("Survey Created successfully");
             
            navigate("/case-manager/survey",{replace: true});

        }else{
            setLoading(false);
        }
       
    }

    return (
        <div style={styles.main}>
         
        <CaseMangerWrapper>

     
        <Card title="Create Survey" style={{ width: '50%', margin: 'auto', marginTop: 50, borderRadius: '5px', boxShadow: '0px 4px 10px rgba(0,0,0,0.1)' }}>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Survey Name" name="surveyName" rules={[{ required: true, message: 'Please input the survey name!' }]}>
                    <Input placeholder="Enter survey name" onChange={(e) => setForm({ ...form, surveyName: e.target.value })} />
                </Form.Item>
                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input the description!' }]}>
                    <TextArea placeholder="Enter description" onChange={(e) => setForm({ ...form, description: e.target.value })} />
                </Form.Item>
                {form.question.map((x, i) => {
                    return (
                        <div key={i}>
                            <Divider orientation="left">{`Question ${i + 1}`}</Divider>
                            <Form.Item label={`Question ${i + 1}`} name={`question${i}`} rules={[{ required: true, message: 'Please input the question!' }]}>
                                <Input name="question" placeholder="Enter question" value={x.question} onChange={e => handleInputChange(e, i)} />
                            </Form.Item>
                            <Form.Item label="Select Field Type" name={`field${i}`} rules={[{ required: true, message: 'Please select the field type!' }]}>
                                <Select name="field" value={x.field} onChange={value => handleInputChange({ target: { name: 'field', value } }, i)}>
                                    <Option value="">Select Field</Option>
                                    <Option value="text">Text</Option>
                                    <Option value="date">Date</Option>
                                    <Option value="radio">Radio</Option>
                                    <Option value="checkbox">Checkbox</Option>
                                </Select>
                            </Form.Item>
                            {x.field === 'radio' || x.field === 'checkbox' ? (
                                <Form.Item label="Answer option">
                                    <Space direction="vertical">
                                        {x.option.map((option, optionIndex) => (
                                            <Form.Item name={`option${i}${optionIndex}`} rules={optionIndex === 0 ? [{ required: true, message: 'Please input the option!' }] : []}>
                                                <Input key={optionIndex} name="option" placeholder={`Option ${optionIndex + 1}`} value={option} onChange={e => handleInputChange(e, i, optionIndex)} />
                                            </Form.Item>
                                        ))}
                                    </Space>
                                </Form.Item>
                            ) : null}
                        </div>
                    );
                })}
                <Button type="dashed" onClick={handleAddQuestion} style={{ width: '60%', margin: '20px 0' }}>Add More question</Button>
                <Button 
    type="primary" 
    htmlType="submit" 
    style={{ width: '100%' }} 
    disabled={loading}
>
    {loading ? <Spin /> : 'Submit'}
</Button>            </Form>
        </Card>
        </CaseMangerWrapper>
        </div>

    );
};

export default SurveyForm;
