import { Modal } from "antd";
import React from "react";
import { MdClose } from "react-icons/md";
  
const AttachmentPreview = ({ data, onClose }) => {
  const docUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
    data?.url
  )}&embedded=true`;
 
  return (
   
    <Modal
      centered
      closeIcon={
        <div style={{ transform: "translate(15px,-16px)" }}>
          {" "}
          <MdClose size={35} color="#000" />
        </div>
      }
      style={{ maxHeight: "95vh" }}
      width={
        data?.type === "application/pdf" ||
        data?.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        data?.type === "application/msword"
          ? "90%"
          : 700
      }
      open
      title={data?.title || false}
      footer={null}
      bodyStyle={{ overflowY: "auto", background: "#fff" }}
      onCancel={onClose}
    >
      {data?.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      data?.type === "application/msword" ? (
        <div style={{ height: "90vh" }}>
          <iframe src={docUrl} width="100%" height="600px" />
        </div>
      ) : (
        <>
          {data?.type === "application/pdf" ? (
            <div style={{ height: "90vh" }}>
              <embed className="w-100 h-100" src={data?.url} />
            </div>
          ) : (
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={data?.url}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default AttachmentPreview;
