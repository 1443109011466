import { Checkbox } from "antd";
import React from "react";
import { BsFilePdf } from "react-icons/bs";
import { FaFileAlt } from "react-icons/fa";

const AttachmentCard = ({
  url,
  hideSelection,
  isSelected,
  onPreview,
  onSelect,
}) => {
  const isDoc = url?.includes(".docx" || ".doc") ?? false;
  const isPdf = url?.includes(".pdf") ?? false;
  return (
    <div
      // hoverable
      onClick={() => onPreview()}
      style={{
        borderRadius: 10,
        overflow: "hidden",
        cursor: onPreview ? "pointer" : "default",
      }}
      bodyStyle={{ padding: 0, paddingTop: 5 }}
      className="border-0 mb-4  bg-none attachment-card  position-relative"
    >
      {isDoc ? (
        <div
          className="bg-white d-flex rounded-base align-items-center justify-content-center position-relative card-image"
          // style={{ height: 111 ,minWidth:111}}
        >
          <div style={{ fontSize: "4rem" }} className="d-flex pt-2 pb-3">
            <FaFileAlt size={70} style={{ color: "#F40F02" }} />
          </div>
        </div>
      ) : (
        <div className="">
          {isPdf ? (
            <div
              className="bg-white d-flex rounded-base align-items-center justify-content-center position-relative card-image"
              // style={{ height: 111 ,minWidth:111}}
            >
              <div style={{ fontSize: "4rem" }} className="d-flex pt-2 pb-3">
                <BsFilePdf size={70} style={{ color: "#F40F02" }} />
              </div>
            </div>
          ) : (
            <img
              alt="example"
              src={url}
              style={{ objectFit: "cover" }}
              className=" card-image w-100"
            />
          )}
        </div>
      )}

      {!hideSelection && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Checkbox
            checked={isSelected}
            onClick={() => onSelect()}
            className="position-absolute"
            style={{ right: 10, top: 10 }}
          />
        </div>
      )}
    </div>
  );
};

export default AttachmentCard;
