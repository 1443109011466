import { createAsyncThunk } from "@reduxjs/toolkit";
import { message, Modal } from "antd";
import moment from "moment/moment";
import { generateOne } from "../../utils/functions";
import { getUsers, postApi } from "./service";
import { initialServiceDetailval } from "./Slice";
import {
  OrderSuccess,
  setExerciseProtocals,
  setExerciseTherapy,
  setSubCategoryByCity,
  setTopCategories,
  setUserInsurance,
  toggleAddNewAdress,
  UpdateplacingOrder,
  setServiceData,
} from "./Slice";

export const getUserAddress = createAsyncThunk(
  "getUserAddress",
  async (payload, { getState }) => {
    try {
      const { user, service } = getState();
      const formData = new FormData();
      formData.append("action", "get_society_user_address");
      formData.append("society_id", user?.user?.society_id);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);
      formData.append("userId", service?.serviceDetail?.user_id);
    
      const res = await postApi(formData, user?.token);
      if (res?.data?.status === 200) {
        return res?.data?.result ?? [];
      }
      return [];
    } catch (error) {
      return [];
    }
  }
);

export const deleteShiping = createAsyncThunk(
  "DeleteShipping",
  async (shippingId, { getState }) => {
    try {
      message.destroy();
      message.loading("Deleteing... please wait.");
      const { user } = getState();
      const formData = new FormData();
      formData.append("action", "delete_user_shipping");
      formData.append("shippingId", shippingId);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);

      const res = await postApi(formData, user?.token);
      if (res?.data?.status === 200) {
        message.destroy();
        message.success("Selected address deleted successfully");
        return shippingId;
      }
      message.destroy();

      message.error("Failed to deleted address");
      return null;
    } catch (err) {
      message.destroy();

      message.error("Failed to delete the address");
      return null;
    }
  }
);

export const getUserList = createAsyncThunk(
  "getPatientList",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    try {
      const { user } = getState();

      const formData = new FormData();
      formData.append("action", "get_patient_list");
      formData.append("societyid", user?.user?.society_id);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);
      formData.append("societyId", user?.user?.society_id);
      if (payload?.f_name) {
        formData.append("f_name", payload?.f_name);
      }
      if (payload?.l_name) {
        formData.append("l_name", payload?.l_name);
      }
      const response = await getUsers(formData, user?.token);
      if (response?.data?.status === 200) {
        return response?.data?.result ?? [];
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }
);

export const saveNewAddress = createAsyncThunk(
  "saveNewAddress",
  async (payload, { getState, dispatch }) => {
    try {
      if (payload?.shippingId) {
        message.destroy();
        message.loading("Updating...  Address");
      } else {
        message.destroy();
        message.loading("Addding... Address");
      }
      const { user } = getState();
      const formData = new FormData();
      // shippingId
      formData.append("society_id", user?.user?.society_id);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);

      Object.keys(payload ?? {}).map((k) => formData.append(k, payload?.[k]));

      const res = await postApi(formData, user?.token);

      if (res?.data?.status === 200) {
        dispatch(getUserAddress());
        if (payload?.shippingId) {
          message.destroy();
          message.success("Address updated successfully");
          dispatch(toggleAddNewAdress(false));
        } else {
          message.destroy();
          message.success("Address added successfully");
          dispatch(toggleAddNewAdress(false));
        }
        return;
      } else {
        if (payload?.shippingId) {
          message.destroy();
          message.error("Failed to update address");
          dispatch(toggleAddNewAdress(false));
        } else {
          message.destroy();
          message.success("Failed to add address");
          dispatch(toggleAddNewAdress(false));
        }
        return null;
      }
    } catch (error) {
      if (payload?.shippingId) {
        message.destroy();
        message.error("Failed to update address");
        dispatch(toggleAddNewAdress(false));
      } else {
        message.destroy();
        message.success("Failed to add address");
        dispatch(toggleAddNewAdress(false));
      }
      return null;
    }
  }
);

export const getServiceCategories = createAsyncThunk(
  "getServiceTypes",
  async (p, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const { serviceDetail } = service;
      const { user: u, token } = user;
      const FD = new FormData();
      FD.append("lat", serviceDetail?.lat || serviceDetail?.latitude);
      FD.append("lng", serviceDetail?.lng || serviceDetail?.latitude);
      FD.append("zipcode", serviceDetail?.pincode || "");
      FD.append("deliverytype",  serviceDetail?.facilityId === "1"?"1": serviceDetail?.deliverytype || 0);
      FD.append("societyId", u?.society_id);
      FD.append("action", "city_category_2");
      FD.append("areacode", u?.areacode);

      FD.append("auterizetokenid", u?.id);
      FD.append("cityId", serviceDetail?.city || "");
      FD.append("userId", u?.id);
      FD.append("isfacility", serviceDetail?.facilityId);
      const res = await postApi(FD, token);
      if (res?.data?.status === 200) {
        dispatch(setTopCategories(res?.data ?? {}));
      } else {
        dispatch(setTopCategories({}));
      }
    } catch (error) {
      dispatch(setTopCategories({}));
    }
  }
);

export const getExerciseTherapy = createAsyncThunk(
  "getExcerciseTherapy",
  async (p, { getState, dispatch }) => {
    const { user } = getState();
    try {
      const { user: u, token } = user;
      const FD = new FormData();

      FD.append("societyId", u?.society_id);
      FD.append("action", "get_exercise_therapy");
      FD.append("auterizetokenid", u?.id);
      FD.append("userId", u?.id);
      const res = await postApi(FD, token);
      if (res?.data?.status) {
        dispatch(setExerciseTherapy(res?.data?.result ?? []));
      }
    } catch (error) {
      dispatch(setExerciseTherapy([]));
    }
  }
);

export const getExerciseProtocals = createAsyncThunk(
  "getExerciseProtocals",
  async (p, { getState, dispatch }) => {
    const { user } = getState();
    try {
      const { user: u, token } = user;
      const FD = new FormData();
      FD.append("societyId", u?.society_id);
      FD.append("action", "get_therapy_protocols");
      FD.append("auterizetokenid", u?.id);
      FD.append("userId", u?.id);
      const res = await postApi(FD, token);

      if (res?.data?.status) {
        dispatch(setExerciseProtocals(res?.data?.result ?? []));
      }
    } catch (error) {
      dispatch(setExerciseProtocals([]));
    }
  }
);

export const getOrderPrice = createAsyncThunk(
  "getOrderPriceForVendor",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const { serviceDetail } = service;

      const serviceId = () => {
        const Ids = [];
        if (serviceDetail?.category_id?.length > 0) {
          (serviceDetail?.category_id ?? []).map((cat_id) =>
            (serviceDetail?.[cat_id] ?? []).map((sub_id) => Ids.push(sub_id))
          );
        }
        return Ids ?? [];
      };

      const formData = new FormData();
      formData.append("partnerId", payload?.partnerId);
      formData.append("serviceId", [...serviceId()].join(","));
      formData.append("action", "get_service_price_for_vendor");
      formData.append("auterizetokenid", user?.user?.id);
      const res = await postApi(formData, user?.token);
      if (res?.status === 200) return res?.data?.service ?? [];
    } catch (error) {
      return [];
    }
  }
);

export const checkAvailability = createAsyncThunk(
  "check_availability",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const FD = new FormData();
      const { user: u, token, serviceDetail } = { ...user, ...service };
      FD.append("zip_code", serviceDetail?.pincode || "");
      FD.append("deliverytype", 0);
      FD.append(
        "dateone",
        moment(serviceDetail?.schedule_date).format("YYYY-MM-DD")
      );
      if (payload?.insuranceId) {
        FD.append("insuranceid", payload?.insuranceId);
      }
      FD.append("action", "check_availablilty");
      FD.append("auterizetokenid", u?.id);
      const res = await postApi(FD, token);
      if (res?.status === 200) {
        if (res?.data?.partnerId) {
          dispatch(getOrderPrice({ partnerId: res?.data?.partnerId }));
        }

        return res?.data ?? {};
      }
    } catch (error) {
      return { loading: false };
    }
  }
);

export const getUserInsurance = createAsyncThunk(
  "getUserInsurance",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const FD = new FormData();
      const { user: u, token, serviceDetail } = { ...user, ...service };
      FD.append("userId", serviceDetail?.user_id);
      FD.append("familymemberId", "");
      FD.append("action", "get_user_insurance_all");
      FD.append("auterizetokenid", u?.id);
      FD.append("clinicId", u?.society_id);
    
      const res = await postApi(FD, token);
      if (res?.data?.status === 200) {
        dispatch(setUserInsurance(res?.data?.result ?? []));
     

      }
    } catch (error) {
      dispatch(setUserInsurance([]));
    }
  }
);
export const getSubcategoryByCity = createAsyncThunk(
  "getSubcategoryByCity",
  (payload, { getState, dispatch }) => {
    try {
    } catch (error) {}
  }
);

export const getCategoryByCity = createAsyncThunk(
  "getCategoryByCity",
  async (payload, { getState, dispatch }) => {
    const { user } = getState();
    try {
      const { user: u, token } = user;
      const FD = new FormData();
      FD.append("category_id", payload?.category_id);
      FD.append("city_id", payload?.city_id);
      FD.append("country", payload?.country);
      FD.append("lat", payload?.lat);
      FD.append("lng", payload?.lng);
      FD.append("action", "all_subcategory_by_city");
      FD.append("auterizetokenid", u?.id);
      FD.append("userId", u?.id);
      const res = await postApi(FD, token);
      if (res?.status === 200) {
        dispatch(
          setSubCategoryByCity({
            [payload?.category_id]: {
              discountAmount: res?.data?.discountAmount || 0,
              data: res?.data?.result ?? [],
            },
          })
        );
      }
    } catch (error) {
      dispatch(setSubCategoryByCity({}));
    }
  }
);
const getFormattedDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

const generateUniqueMessageID = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  // Generate a random 4-digit number to ensure uniqueness
  const randomNum = String(Math.floor(1000 + Math.random() * 9000)); // Random 4-digit number

  return `MSGID${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
};
const generateUniqID = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  // Generate a random 4-digit number to ensure uniqueness
  const randomNum = String(Math.floor(1000 + Math.random() * 9000)); // Random 4-digit number

  return `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
};

const formatDate = (dateString) => {
  const [month, day, year] = dateString.split("-");
  return `${year}${month.padStart(2, "0")}${day.padStart(2, "0")}`;
};

const getGenderAbbreviation = (gender) => {
  return gender.toLowerCase() === "male" ? "M" : gender.toLowerCase() === "female" ? "F" : gender;
};


function buildDynamicHL7(data) {
  
  // Construct MSH segment
  const msh = `MSH|^~\\&|${data.sending_application}|${data.sending_facility}|${data.receiving_application}|${data.receiving_facility}|${data.transaction_datetime}||${data.message_type}|${data.message_control_id}|${data.processing_id}|${data.version}`;
  
  // Construct PID segment
  const pid = `PID|||MRN${data.patient_id}||${data.patient_name_last}^${data.patient_name_first}^${data.patient_name_middle}^${data.patient_name_suffix}||${data.patient_dob}|${data.patient_gender}|||${data.patient_street}^^${data.patient_city}^${data.patient_state}^${data.patient_zip}^${data.patient_country}||${data.phone}`;

  // Construct IN1 segment
  const in1 = `IN1|1|${data.insurance_plan_id}|${data.insurance_company_id}|${data.insurance_company_name}||||${data.insurance_group_number}||||||||${data.insurance_lname}^${data.insurance_fname}^${data.patient_name_middle}^${data.patient_name_suffix}|${data.insured_person_relationship}|||||||||||||||||||${data.insurance_policy_number}`;

  // Construct PV1 segment
  const pv1 = `PV1||${data.patient_class}|${data.assigned_patient_location_room}^${data.assigned_patient_location_bed}||||${data.attending_doctor_id}^${data.attending_doctor_last_name}^${data.attending_doctor_first_name}^^^^^^^^^^NPI`;

  // Construct ORC segment
  const orc = `ORC|${data.order_control}|${data.placer_order_number}|||||||${data.order_date_time}`;

  // Construct OBR segment(s) for each universal service.
  // We want:
  //   • OBR-4 = study_code^study_description^L
  //   • OBR-5 = Order Priority (now set dynamically)
  //   • OBR-24 = cpt_code^cpt_description^CPT
  const obrSegments = data.universal_services.map((service, index) => {
    const obrFields = [];

    // Field 0 is always the segment name:
    obrFields[0] = "OBR";

    // OBR-1: Set ID
    obrFields[1] = index + 1;

    // OBR-2: Placer Order Number
    obrFields[2] = service.placer_order_number;

    // OBR-3: Filler Order Number (left empty)
    obrFields[3] = "";

    // OBR-4: Universal Service Identifier (Study Code)
    obrFields[4] = `${service.study_code}^${service.study_description}^L`;

    // OBR-5: Priority – now set dynamically from the data object
    obrFields[5] = data.order_priority || "";

    // OBR-6: Requested Date/Time (empty)
    obrFields[6] = "";

    // OBR-7: Observation Date/Time (if provided)
    obrFields[7] = service.observation_datetime || "";

    // Fill OBR-8 through OBR-23 with empty strings
    for (let i = 8; i <= 23; i++) {
      obrFields[i] = "";
    }
    obrFields[16] = `${data.attending_doctor_id}^${data.attending_doctor_last_name}^${data.attending_doctor_first_name}^^^^^^^^^^NPI`;
    // OBR-24: Procedure Code / Diagnostic Service Section ID (CPT Code)
    for (let i = 17; i <= 23; i++) {
      obrFields[i] = "";
    }
    obrFields[24] = `${service.cpt_code}^${service.cpt_description}^CPT`;

    // Join the fields with the HL7 pipe separator.
    return obrFields.join("|");
  }).join("\n");

  // Construct NTE segment for notes, if any
  const nte = data.order_notes ? `NTE|1||${data.order_notes}` : "";

  // Combine all segments (filter will remove any empty segments)
  const hl7Message = [msh, pid, in1, pv1, orc, obrSegments, nte].filter(Boolean).join("\n");
  return hl7Message;
}

export const placeOrder = createAsyncThunk(
  "placeOrder",
  async (payload, { getState, dispatch }) => {
    const { user, service } = getState();
    try {
      const { category_id ,vendorid} = payload;
      const {
        user: u,
        token,
        serviceDetail,
        addressList,
        userInsurance,
        topCategories,
      } = { ...user, ...service };
      const FD = new FormData();
      if(category_id &&  category_id == "848" && serviceDetail?.imagingsubid.length){
      
      const adrslist = addressList?.data?.find(
        (e) => e?.shippingId === serviceDetail?.shippingId
      ) || null; // Ensures it returns `null` if nothing is found

      const inslist = userInsurance?.find(
        (e) => e?.insuranceId == serviceDetail?.insuranceId
      ) || null; 

     

   

      
      // Ensures it returns `null` if nothing is found
     
       const vendorname = serviceDetail?.vendors.find(e=>
        e?.partnerId == vendorid
       ) || null; // Ensures it returns `null` if nothing is found
       const placeID = generateUniqID();
       var uServices = []
       serviceDetail?.imagingsubData.forEach((d)=>{
         uServices.push({
          placer_order_number: placeID,
          study_code: d?.study_code || "",
          study_description: d?.sub_category_name || "",
          cpt_code: d?.cpt_code || "",
          cpt_description: "",
          observation_datetime: ""  // if applicable, or provide a date/time
         })
       })
      
      const dynamicData = { 
        // Message Header details
        sending_application: "MedsetGo",
        message_type: "ORM^O01",
        processing_id: "P",
        version: "2.3",
        
        // Patient info (PID)
        patient_name_middle: "",
        patient_name_suffix: "",
        patient_class: "O",
        assigned_patient_location_room: "OP",
        assigned_patient_location_bed: "",
        admit_datetime: "",
        order_control: "NW",
        sending_facility: u?.society_name || "",
        receiving_application: "LIS",
        receiving_facility: vendorname?.OrganizationName || "",
        transaction_datetime: getFormattedDateTime(),
        message_control_id: generateUniqueMessageID(),
        patient_id:  serviceDetail?.user_id,
        patient_name_first: serviceDetail?.first_name,
        patient_name_last: serviceDetail?.last_name,
        phone: serviceDetail?.phone|| "",
      //  patient_dob: "19670824",
        patient_dob: formatDate(serviceDetail?.dob) || "",
        patient_gender: getGenderAbbreviation(serviceDetail?.gender),
        patient_street: adrslist?.street_name || "",
        patient_city: adrslist?.city || "" ,
        patient_state: adrslist?.state || "",
        patient_zip: adrslist?.pincode || "",
        patient_country: adrslist?.country || "",
        
        // Order info
        attending_doctor_id: serviceDetail?.selectedDoctor?.npi_number  || "",
        attending_doctor_last_name: serviceDetail?.selectedDoctor?.l_name || "",
        attending_doctor_first_name: serviceDetail?.selectedDoctor?.f_name || "" ,
        order_date_time: getFormattedDateTime(),
        order_notes:  serviceDetail?.prescription_text || "",
        insurance_plan_id:  inslist?.PayerId || "",
        insurance_company_id:  "" ,
        insurance_fname : inslist?.l_name || "",
        insurance_lname : inslist?.f_name || "",
        insurance_dob :    "",
        
        insurance_company_name: inslist?.insuranceCarrier || "",
        insurance_policy_number: inslist?.memberId,  
        insurance_group_number: inslist?.account_number || "", 
        insured_person_relationship: inslist?.relation || "",
        placer_order_number: placeID,
        
        // New property for Priority. For example "STAT", "ROUTINE", etc.
        order_priority: "S",
        
        universal_services: uServices,
      };
     const hl7 =  buildDynamicHL7(dynamicData)
     console.log(hl7);
     
     FD.append("hl7message", hl7);

    }


      FD.append("action", "new_payment");
      FD.append("cpt", serviceDetail?.cpt || "");
      FD.append("dx", serviceDetail?.dx || "");
      FD.append("first_name", serviceDetail?.first_name || "");
      FD.append("last_name", serviceDetail?.last_name || "");
      FD.append("email", serviceDetail?.email || "");
      FD.append("phone", serviceDetail?.phone || "");
      FD.append("latitude", serviceDetail?.latitude || "");
      FD.append("longitude", serviceDetail?.longitude || "");
      FD.append(
        "schedule_date",
        serviceDetail?.schedule_date !== ""
          ? moment(serviceDetail?.schedule_date, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            )
          : ""
      );
      FD.append("appointmentid", serviceDetail?.appointmentid || "0");
      FD.append("patientId", serviceDetail?.patientId || "0");
      FD.append("payment_amount", serviceDetail?.payment_amount || "");
      FD.append(
        "execution_date",
        serviceDetail?.execution_date !== ""
          ? moment(serviceDetail?.execution_date, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            )
          : ""
      );
      FD.append("visitid", serviceDetail?.visitid || "");
      FD.append("timeSlot", "");
      FD.append("timeslotid", "");
      FD.append("device_type", "web");
      FD.append("timeslotid", "");
      FD.append("category_id", category_id);
      FD.append("user_id", serviceDetail?.user_id);
      FD.append("casemanager", u?.id);
      FD.append("partnerselection", serviceDetail?.partnerselection || "0");

      FD.append("OTHDec", "");
      FD.append("country", serviceDetail?.country || "");

      FD.append("usershipping_id", serviceDetail?.shippingId || "0");
      FD.append("is_fleet", "");
      FD.append("isPharmacy", "");
      FD.append("vendorid", vendorid);
      FD.append("no_occurence", serviceDetail?.no_occurence);
      FD.append("iscarservice", 0);
      FD.append("walletMoney", 0);
      FD.append("referalid", 0);
      FD.append("occonweekly", serviceDetail?.occonweekly);
      FD.append("Isinsurance", serviceDetail?.Isinsurance || 0);
      if (serviceDetail?.Isinsurance > 0) {
        FD.append("Isinsurance", serviceDetail?.Isinsurance || "");
      }
      FD.append("insuranceId", serviceDetail?.insuranceId || "0");
      FD.append("doctorId", serviceDetail?.doctorId || "");
       if(category_id &&  category_id == "848" && serviceDetail?.imagingsubid.length){
        FD.append("services", serviceDetail?.imagingsubid.join());
        FD.append(
          "quantity",
          serviceDetail?.imagingsubid?.length > 0
            ? generateOne(serviceDetail?.imagingsubid?.length ?? 1)
            : 1
        );
     // dispatch(setServiceData({ imagingsubid: [] }));
     
       }else{
      FD.append("services", category_id && serviceDetail?.[category_id].join());
      FD.append(
        "quantity",
        serviceDetail?.[category_id]?.length > 0
          ? generateOne(serviceDetail?.[category_id]?.length ?? 1)
          : 1
      );
       }
    
      // -----------------only for "Physical Therapy"
      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Physical Therapy"
        )?.length > 0
      ) {
        FD.append(
          "exercise_therapy",
          JSON.stringify({
            exercise_therapy: serviceDetail?.exercise_therapy || [],
          })
        );

        FD.append(
          "therapy_protocols",
          JSON.stringify({
            therapy_protocols: serviceDetail?.therapy_protocols || [],
          })
        );
      }
      // ----------------only for   "Home Hospice Care"
      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Home Hospice Care"
        )?.length > 0
      ) {
        FD.append(
          "patient_location",
          JSON.stringify({
            patient_location: serviceDetail?.patient_location || [],
          })
        );
        FD.append("dme", JSON.stringify({ dme: serviceDetail?.dme || [] }));
        FD.append("DNR", serviceDetail?.DNR || 2);
        FD.append("AD", serviceDetail?.AD || 2);
        FD.append(
          "terminal_diagnosis",
          serviceDetail?.terminal_diagnosis || ""
        );
        FD.append("co_morbidities", serviceDetail?.co_morbidities || "");
      }

      // ----------only for -- "Comprehensive Nursing Care"

      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Comprehensive Nursing Care"
        )?.length > 0
      ) {
        FD.append(
          "exercise_therapy",
          JSON.stringify({
            exercise_therapy: serviceDetail?.exercise_therapy || [],
          })
        );

        FD.append(
          "therapy_protocols",
          JSON.stringify({
            therapy_protocols: serviceDetail?.therapy_protocols || [],
          })
        );
      }

      // ------
      FD.append("home_health", JSON.stringify({}));
      FD.append("primary_diagnosis", "");
      FD.append("SN", JSON.stringify({}));

      FD.append("orders", "");
      FD.append("societyId", u?.society_id || "");
      FD.append("ampm", serviceDetail?.ampm || "");
      FD.append("auterizetokenid", u?.id);

      FD.append("deliverytype", serviceDetail?.deliveryType);
      FD.append("facilityId", serviceDetail?.facilityId);
      FD.append(
        "appointmentInstructions",
        serviceDetail?.appointmentInstructions || ""
      );
      FD.append("prescription_text", serviceDetail?.prescription_text);
      FD.append("mri", JSON.stringify(serviceDetail?.mriScanData));
      FD.append("mr_extermity", JSON.stringify(serviceDetail?.mrExtremity));
      FD.append("mr_angioraphy", JSON.stringify(serviceDetail?.mrAngiography));
      FD.append(
        "mr_arthrogrphy",
        JSON.stringify(serviceDetail?.mrArthrography)
      );
      FD.append("ct", JSON.stringify(serviceDetail?.ctScan));
      FD.append("ct_angioraphy", JSON.stringify(serviceDetail?.ctAngiography));
      FD.append("ultrasound", JSON.stringify(serviceDetail?.ultraSound));
      FD.append("x_ray", JSON.stringify(serviceDetail?.xRay));
      FD.append(
        "nuclear_medicine",
        JSON.stringify(serviceDetail?.nuclearMedicine)
      );
      FD.append("fluoroscopy", JSON.stringify(serviceDetail?.fluoroScopy));
      FD.append("dexa", JSON.stringify(serviceDetail?.dexa));
      FD.append("priorityId", serviceDetail?.orderClassificationValue);
      FD.append("ultrasoundatt", serviceDetail?.ultraSoundRadioValue || "");

      if (
        topCategories?.topcategories?.filter(
          (el) =>
            el?.category_id === category_id &&
            el?.category_name === "Durable Medical Equipment"
        )?.length > 0
      ) {
        FD.append("dme_code", serviceDetail?.dmeCodesList || "");
      } else {
        FD.append("dme_code", "");
      }

      if ((serviceDetail?.prescriptions ?? []).length > 0) {
        (serviceDetail?.prescriptions ?? []).forEach((file) => {
          FD.append("prescriptions[]", file?.originFileObj, file.name);
        });
      }

      FD.append("attachmentIds", serviceDetail?.attachmentIds || "");
      //  console.log("vendorid", serviceDetail?.vendorid);

      const res = await postApi(FD, token);
      if (res?.status === 200 && res?.data.status === 200) {
        dispatch(setServiceData({ ...initialServiceDetailval }));
        
        dispatch(UpdateplacingOrder(false));
        dispatch(OrderSuccess(true));
      } else {
        Modal.warning({
          title: "Something went wrong, please try again",
        });
        dispatch(UpdateplacingOrder(false));
      }
    } catch (error) {
      dispatch(setServiceData({ ...initialServiceDetailval }));
      dispatch(UpdateplacingOrder(false));
    }
  }
);
