
import React, { useState, useEffect, useRef } from "react";
import { Button, Input, Spin } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import { APIHitCall } from "../../../features/case-manager/caseManagerService";
import moment from "moment";
import { CaseMangerWrapper } from "../../Wrapper";

const ReferralChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [referralData, setReferralData] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(({ user }) => ({ ...user }));
  const messagesEndRef = useRef(null);
  const AI_API_KEY = process.env.REACT_APP_OPENAI_KEY;
  const AI_API_URL = "https://api.openai.com/v1/chat/completions";
  const CURRENT_DATE = new Date().toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).replace(/\//g, "-"); // e.g., "03-18-2025"

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
  }, [messages]);

  useEffect(() => {
    setMessages([
      { sender: "AI", text: `Hello! I’m here to help you book a referral` },
    ]);
    fetchServiceCategories();
  }, []);

  /** Fetch available service categories from the backend */
  const fetchServiceCategories = async () => {
    setLoading(true);
    const FD = new FormData();
    FD.append("action", "areacode_subcategory");
    FD.append("areacode", user?.areacode);
    try {
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        const categories = res.data.topcategories.map((cat) => ({
          id: cat.category_id,
          name: cat.category_name,
          isFacility: cat.isFacility,
          ...cat,
        }));
        setReferralData((prev) => ({ ...prev, serviceCategories: categories }));
      }
    } catch (err) {
      console.error("Failed to fetch service categories:", err);
    } finally {
      setLoading(false);
    }
  };

  /** Search for patients by name or identifier */
  const searchPatient = async (query) => {
    const FD = new FormData();
    FD.append("keyword", query);
    FD.append("societyId", user?.society_id);
    FD.append("action", "get_patient_detail_list");
    FD.append("auterizetokenid", user?.id);
    FD.append("casemanager", user?.id);
    try {
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200 && res.data.result.length > 0) {
        return res.data.result.map((p) => ({
          patientid: p.patientid,
          f_name: p.f_name,
          l_name: p.l_name,
          phone: p.phone,
          email: p.email,
          dob: p.dob,
          gender: p.gender,
          insurancedata: p.insurancedata,
          address: p.address[0],
          ...p,
        }));
      }
      return [];
    } catch (err) {
      throw new Error("Failed to search for patient.");
    }
  };
  
  /** Submit the referral with collected data, including insurance */
  const submitReferral = async (data,vendorid) => {
    const FD = new FormData();
    FD.append("action", "new_payment");
    FD.append("user_id", data.patient?.patientid || "");
    FD.append("usershipping_id", data.address?.usershipping_id || "");
    FD.append("insuranceId", data.selectedInsurance?.id || "");
    FD.append("services", data.selectedCategories.map(cat => cat.id).join(",") || "");
    FD.append("vendorid", vendorid.partnerId);
    FD.append("quantity", "1");

    if(data.selectLocationValue == "1"){
      FD.append("deliverytype", "0");
      FD.append("facilityId", "0");


    }else{
      FD.append("deliverytype", "1");
      FD.append("facilityId", "1");


    }
    FD.append("device_type", "web");
FD.append(
        "schedule_date",
        data?.schedule_date !== ""
          ? moment(data?.schedule_date, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            )
          : ""
      );
      FD.append("societyId", user?.society_id);
      FD.append("auterizetokenid", user?.id);

    FD.append("prescription_text", data.note || "");
    FD.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    try {
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200 ) {
      
        return { success: true, message: "Referral submitted successfully!" };
          
      }else{
        return { success: false, message: "Please try again after some time!" };
      }
    } catch (err) {
      return { success: false, message: "Please try again after some time!" };


    } finally {
      setLoading(false);
    }
   
  };

  /** Define AI tools */
  const tools = [
    {
      type: "function",
      function: {
        name: "searchPatient",
        description: "Search for a patient by name or identifier, including their address and insurance data",
        parameters: {
          type: "object",
          properties: { query: { type: "string", description: "Name or ID" } },
          required: ["query"],
        },
      },
    },
    {
      type: "function",
      function: {
        name: "submitReferral",
        description: "Submit the referral with collected data, including insurance",
        parameters: {
          type: "object",
          properties: {
            patient: { type: "object" },
            selectLocationValue: { type: "string", enum: ["1", "3"] },
            address: { type: "object" },
            selectedCategories: { type: "array", items: { type: "object" } },
            schedule_date: { type: "string" },
            note: { type: "string" },
            selectedInsurance: { type: "object", properties: { id: { type: "string" }, ins_com: { type: "string" } } },
          },
          required: ["patient", "selectLocationValue", "selectedCategories", "schedule_date"],
        },
      },
    },
    {
      type: "function",
      function: {
        name: "setReferralData",
        description: "Set or update referral data fields cumulatively, including insurance",
        parameters: {
          type: "object",
          properties: {
            patient: { type: "object" },
            selectedCategories: { type: "array", items: { type: "object" } },
            selectLocationValue: { type: "string", enum: ["1", "3"] },
            schedule_date: { type: "string" },
            note: { type: "string" },
            selectedInsurance: { type: "object", properties: { id: { type: "string" }, ins_com: { type: "string" } } },
          },
        },
      },
    },
  ];

  /** Call the AI model with user input and conversation history */
  const callChatGPT = async (userInput) => {
    const patientDetails = referralData.patient
      ? `
        ### Selected Patient Details:
        - Full Name: ${referralData.patient.f_name} ${referralData.patient.l_name}
        - Patient ID: ${referralData.patient.patientid}
        - Date of Birth: ${referralData.patient.dob}
        - Phone: ${referralData.patient.phone || "Not provided"}
        - Email: ${referralData.patient.email || "Not provided"}
        - Gender: ${referralData.patient.gender || "Not provided"}
        - Address: ${referralData.patient.address?.address || "Not provided"}
        - Insurance Options: ${referralData.patient.insurancedata?.map((ins, i) => `${i + 1}. ${ins.ins_com} (ID: ${ins.id})`).join(", ") || "None"}
        - Selected Insurance: ${referralData.selectedInsurance ? `${referralData.selectedInsurance.ins_com} (ID: ${referralData.selectedInsurance.id})` : "First available insurance by default"}
      `
      : "No patient selected yet.";

    const systemPrompt = `
      You are an intelligent AI assistant designed to book referrals naturally and efficiently, handling every step with AI-driven logic. Your goal is to provide a seamless experience by extracting all necessary information from the user's inputs and conversation history in a single pass when possible, making decisions, and guiding the user conversationally. Use your natural language understanding to interpret all inputs dynamically, including dates (e.g., "tomorrow", "30 March"), locations (e.g., "at home", "at facility" or "at facilty"), notes (e.g., "note is dont eat anything"), and insurance selection (e.g., "use insurance AETNA"). Correct common typos (e.g., "physial" → "physical") and proceed proactively.

      ${patientDetails}

      ### Steps (All AI-Driven):
      1. **Patient Selection**: 
         - Extract the patient name from the input (e.g., "david" from "book physical therapy for david on march 30").
         - Call 'searchPatient' with the name. If one match, set patient data with all details (name, DOB, phone, address, insurancedata) and confirm their full name (e.g., "David Warner"). Default to the first insurance in 'insurancedata' as 'selectedInsurance'.
         - If multiple matches, list them with numbers and details (e.g., "1. David Warner - DOB: 11-02-1970, Phone: 998-806-4177, Insurance: AETNA, Demo Insurance") and ask to pick a number.
         - If none, ask for more details (e.g., "I couldn’t find 'david'. Please clarify.").

      2. **Service Category**: 
         - Extract the service from the input (e.g., "physical therapy"). Available categories: ${referralData.serviceCategories?.map(c => `${c.name} (ID: ${c.id}) (parent:${c.parent_category_id})`).join(", ") || "loading..."}.
         - Correct typos (e.g., "physial" → "physical") and match to the closest category.
         - Call 'setReferralData' with the category and 'selectLocationValue' ("1" for Home if isFacility is "0", "3" for Facility if isFacility is "1"). Override with user input if "at home" or "at facility" (or "at facilty") is specified.

      3. **Location**: 
         - Set by the service category’s 'isFacility' field by default ("0" → "1" Home, "1" → "3" Facility). Allow override if user specifies "at home" or "at facility" (or "at facilty") in the input.

      4. **Schedule**: 
         - Extract date from the input (e.g., "30 March" or "March 30" → "03-30-2025" from ${CURRENT_DATE}, "tomorrow" → "03-19-2025"). Convert to MM-DD-YYYY using the current year (2025) unless specified otherwise.
         - Call 'setReferralData' with 'schedule_date' once per response.

      5. **Insurance Selection**: 
         - Default to the first insurance in the patient’s 'insurancedata'. 
         - Allow override if user specifies (e.g., "use insurance Demo Insurance" or "use insurance 2"). Match by name (case-insensitive) or index (1-based). If invalid, list options from the patient’s insurancedata and ask for clarification.
         - Call 'setReferralData' with 'selectedInsurance' when changed, ensuring both 'id' and 'ins_com' are included.

      6. **Note (Optional)**:
     - Dynamically extract a note from the input using natural language understanding. A note can be explicitly marked (e.g., "note is dont eat", "add a note to rest") or implied as additional context after patient, service, date, location, and insurance (e.g., "dont eat that day", "please rest well").
     - Look for keywords like "note", "message", "add", or "include" as hints, but don’t require them—rely on context to infer intent.
     - If the intent is unclear (e.g., additional text could be a note or something else), ask for clarification (e.g., "Did you mean 'dont eat that day' as a note?").
     - Call 'setReferralData' with 'note' if identified; reset to empty string if no note is present in the current input.

      7. **Submission**: 
         - If all required fields are set (patient, category, location, date), summarize (e.g., "Patient: David Warner, Service: Physical Therapy, Location: Facility, Date: 03-30-2025, Insurance: AETNA (ID: 38025), Note: None") and ask "Shall I submit this?".
         - On "yes", call 'submitReferral'. Respond "Referral submitted successfully!" and reset all but service categories.

      ### Guidelines:
      - **Full Control**: Interpret inputs holistically—extract patient, service, date, location, insurance, and note from a single input when provided (e.g., "book physical therapy for david on march 30 at facility, use insurance Demo Insurance, note is rest well").
      - **Context**: Retain all fields until submission/reset, but reset 'note' to empty string if not provided. Default 'selectedInsurance' to patient’s first insurance unless overridden.
      - **Proactive**: Ask for missing fields or clarify insurance if invalid; summarize when complete. Use patient details (e.g., address, DOB) to inform responses if relevant.
      - **Progressive**: Build on prior steps; don’t backtrack unless necessary.
      - **Natural**: After 'setReferralData', if incomplete, guide the user to the next step (e.g., "When should this be scheduled?"). If complete, return a summary and confirmation.
      - **Robustness**: Handle typos (e.g., "physial" → "physical", "facilty" → "facility") and flexible date formats (e.g., "30 March" or "March 30").

      ### Current State:
      - Patient: ${referralData.patient ? `${referralData.patient.f_name} ${referralData.patient.l_name}` : "Not set"}
      - Service: ${referralData.selectedCategories?.length ? referralData.selectedCategories[0].name : "Not set"}
      - Location: ${referralData.selectLocationValue ? (referralData.selectLocationValue === "1" ? "Home" : "Facility") : "Not set"}
      - Date: ${referralData.schedule_date || "Not set"}
      - Insurance: ${referralData.selectedInsurance ? `${referralData.selectedInsurance.ins_com} (ID: ${referralData.selectedInsurance.id})` : "Not set"}
      - Note: ${referralData.note || "Not set"}
    `;

    const aiMessages = [
      { role: "system", content: systemPrompt },
      ...messages.map((msg) => ({ role: msg.sender === "AI" ? "assistant" : "user", content: msg.text })),
      { role: "user", content: userInput },
    ];

    let tempReferralData = { ...referralData, note: "" }; // Reset note each time
    let aiResponse = "";

    try {
      const response = await axios.post(
        AI_API_URL,
        {
          model: "gpt-4o",
          messages: aiMessages,
          tools: tools,
          tool_choice: "auto",
          max_tokens: 500,
          temperature: 0.3,
        },
        {
          headers: {
            Authorization: `Bearer ${AI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const message = response.data.choices[0].message;

      if (message.tool_calls) {
        for (const toolCall of message.tool_calls) {
          const functionName = toolCall.function.name;
          const args = JSON.parse(toolCall.function.arguments);

          if (functionName === "searchPatient") {
            const patients = await searchPatient(args.query);
            if (patients.length === 1) {
              tempReferralData = {
                ...tempReferralData,
                patient: patients[0],
                address: patients[0].address,
                selectedInsurance: patients[0].insurancedata[0], // Default to first insurance
              };
              const fullInput = userInput.toLowerCase(); // Only use current input for initial parsing
              const category = tempReferralData.serviceCategories?.find(c =>
                fullInput.includes(c.name.toLowerCase()) || fullInput.includes("physial")
              );
              if (category) {
                tempReferralData = {
                  ...tempReferralData,
                  selectedCategories: [category],
                  selectLocationValue: category.isFacility === "0" ? "1" : "3",
                };
                if (fullInput.includes("at home")) {
                  tempReferralData.selectLocationValue = "1";
                } else if (fullInput.includes("at facil")) {
                  tempReferralData.selectLocationValue = "3";
                }
                // Improved date parsing
                const dateMatch = fullInput.match(/((\d{1,2}\s+(january|february|march|april|may|june|july|august|september|october|november|december))|((january|february|march|april|may|june|july|august|september|october|november|december)\s+\d{1,2})|tomorrow)/i);
                if (dateMatch) {
                  let formattedDate;
                  if (dateMatch[0].toLowerCase() === "tomorrow") {
                    const tomorrow = new Date();
                    tomorrow.setDate(tomorrow.getDate() + 1);
                    formattedDate = tomorrow.toLocaleDateString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    }).replace(/\//g, "-");
                  } else {
                    let day, month;
                    if (/\d{1,2}\s/.test(dateMatch[0])) {
                      [day, month] = dateMatch[0].split(/\s+/);
                    } else {
                      [month, day] = dateMatch[0].split(/\s+/);
                    }
                    const monthMap = {
                      january: "01", february: "02", march: "03", april: "04", may: "05", june: "06",
                      july: "07", august: "08", september: "09", october: "10", november: "11", december: "12"
                    };
                    formattedDate = `${monthMap[month.toLowerCase()]}-${day.padStart(2, "0")}-2025`;
                    tempReferralData.schedule_date = formattedDate; // Set date directly here
                  }
                }
                const insuranceMatch = fullInput.match(/use insurance\s+(.+)$/i);
                if (insuranceMatch && tempReferralData.patient.insurancedata) {
                  const insuranceInput = insuranceMatch[1].trim();
                  const insuranceOptions = tempReferralData.patient.insurancedata;
                  const selectedByName = insuranceOptions.find(ins => ins.ins_com.toLowerCase().includes(insuranceInput.toLowerCase()));
                  const selectedByIndex = !isNaN(insuranceInput) && insuranceOptions[parseInt(insuranceInput) - 1];
                  if (selectedByName) {
                    tempReferralData.selectedInsurance = selectedByName;
                  } else if (selectedByIndex) {
                    tempReferralData.selectedInsurance = selectedByIndex;
                  } else {
                    aiResponse = `I couldn’t match "${insuranceInput}" to an insurance. Options are:\n${insuranceOptions.map((ins, i) => `${i + 1}. ${ins.ins_com} (ID: ${ins.id})`).join("\n")}\nPlease specify by name or number (e.g., "use insurance 2").`;
                  }
                }
                const noteMatch = fullInput.match(/(note is|message is)\s+(.+)$/i);
                if (noteMatch) {
                  tempReferralData.note = noteMatch[2].trim();
                }
              }
              if (!aiResponse) {
                if (
                  tempReferralData.patient &&
                  tempReferralData.selectedCategories?.length &&
                  tempReferralData.selectLocationValue &&
                  tempReferralData.schedule_date
                ) {
                  aiResponse = `Here’s the summary of the referral:\n- Patient: ${tempReferralData.patient.f_name} ${tempReferralData.patient.l_name}\n- Service: ${tempReferralData.selectedCategories[0].name}\n- Location: ${tempReferralData.selectLocationValue === "1" ? "Home" : "Facility"}\n- Date: ${tempReferralData.schedule_date}\n- Insurance: ${tempReferralData.selectedInsurance?.ins_com || "None"} (ID: ${tempReferralData.selectedInsurance?.id || "None"})\n- Note: ${tempReferralData.note || "None"}\nShall I submit this?`;
                } else if (category) {
                  aiResponse = `I’ve found ${tempReferralData.patient.f_name} ${tempReferralData.patient.l_name} and set them for ${category.name} at ${tempReferralData.selectLocationValue === "1" ? "home" : "a facility"}. When should this be scheduled? I need it in MM-DD-YYYY format (e.g., 03-30-2025).`;
                } else {
                  aiResponse = `I’ve found ${tempReferralData.patient.f_name} ${tempReferralData.patient.l_name}. What service do they need (e.g., Physical Therapy)?`;
                }
              }
            } else if (patients.length > 1) {
              aiResponse = `Multiple patients found:\n${patients
                .map((p, i) => `${i + 1}. ${p.f_name} ${p.l_name} - DOB: ${p.dob}, Phone: ${p.phone}, Insurance: ${p.insurancedata.map(ins => `${ins.ins_com} (ID: ${ins.id})`).join(", ")}, Address: ${p.address?.address || "undefined"}`)
                .join("\n")}\nPlease pick a number (1-${patients.length}) or click on a patient to select.`;
              tempReferralData = { ...tempReferralData, patientMatches: patients };
            } else {
              aiResponse = `I couldn’t find anyone matching "${args.query}". Did you mean "David"? Please clarify or provide more details (e.g., full name or ID).`;
            }
          } else if (functionName === "setReferralData") {
            tempReferralData = { ...tempReferralData, ...args };
            if (!tempReferralData.schedule_date && tempReferralData.patient && tempReferralData.selectedCategories?.length) {
              aiResponse = `I’ve set ${tempReferralData.patient.f_name} ${tempReferralData.patient.l_name} for ${tempReferralData.selectedCategories[0].name} at ${tempReferralData.selectLocationValue === "1" ? "home" : "a facility"} with insurance ${tempReferralData.selectedInsurance?.ins_com || "None"} (ID: ${tempReferralData.selectedInsurance?.id || "None"}). When should this be scheduled? I need it in MM-DD-YYYY format (e.g., 03-30-2025).`;
            }
          } else if (functionName === "submitReferral") {
           const vendorid = await getvendor(tempReferralData)
            const result = await submitReferral(tempReferralData,vendorid);
            if (result.success) {
              tempReferralData = { serviceCategories: tempReferralData.serviceCategories }; // Reset
              aiResponse = "Referral submitted successfully!";
            } else {
              aiResponse = `Something went wrong: ${result.message}. Here’s what I have: Patient: ${tempReferralData.patient?.f_name} ${tempReferralData.patient?.l_name}, Service: ${tempReferralData.selectedCategories?.[0]?.name}, Location: ${tempReferralData.selectLocationValue === "1" ? "Home" : "Facility"}, Date: ${tempReferralData.schedule_date}, Insurance: ${tempReferralData.selectedInsurance?.ins_com || "None"} (ID: ${tempReferralData.selectedInsurance?.id || "None"}), Note: ${tempReferralData.note || "None"}. What should we fix?`;
            }
          }
        }

        setReferralData(tempReferralData);

        if (
          tempReferralData.patient &&
          tempReferralData.selectedCategories?.length &&
          tempReferralData.selectLocationValue &&
          tempReferralData.schedule_date &&
          !aiResponse
        ) {
          aiResponse = `Here’s the summary of the referral:\n- Patient: ${tempReferralData.patient.f_name} ${tempReferralData.patient.l_name}\n- Service: ${tempReferralData.selectedCategories[0].name}\n- Location: ${tempReferralData.selectLocationValue === "1" ? "Home" : "Facility"}\n- Date: ${tempReferralData.schedule_date}\n- Insurance: ${tempReferralData.selectedInsurance?.ins_com || "None"} (ID: ${tempReferralData.selectedInsurance?.id || "None"})\n- Note: ${tempReferralData.note || "None"}\nShall I submit this?`;
        }
      } else {
        aiResponse = message.content || "Can you tell me more about what you’d like to book?";
      }

      return aiResponse || "Got it, what’s the next step?";
    } catch (err) {
      console.error("AI API error:", err);
      return `Sorry, I hit a snag (${err.message}). Let’s try again—what did you want to book?`;
    }
  };

    const getvendor = async (data) => {
    setLoading(true);
    const FD = new FormData();
    FD.append("action", "get_vendor_list_by_zip");
    FD.append("zipcode", "0");
    FD.append("areacode", user?.areacode);
    FD.append("memberId", 0);
    FD.append("category_id", data.selectedCategories[0].parent_category_id || "");

    FD.append("patientId", data.patient?.patientid);
   // FD.append("category_id", serviceDetail?.category_id[0] || "");

    FD.append("insuranceid", data.selectedInsurance?.id || "");
    if(data.selectLocationValue == "1"){

    FD.append("facility",  "0");
    }else{
      FD.append("facility", "1");
    }

    FD.append("auterizetokenid", user?.id);
    try {
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200 && res.data?.vendors  && res.data?.vendors.length > 0) {
       const vendorid = res?.data?.vendors[0]
        return vendorid;
          
      }else{
        return null;
      }
    } catch (err) {
      console.error("Failed to fetch service categories:", err);
      return null;

    } finally {
      setLoading(false);
    }
  };

  /** Handle patient selection */
  const handlePatientSelection = (patientIndex) => {
    const patient = referralData.patientMatches[patientIndex];
    let tempReferralData = {
      ...referralData,
      patient,
      patientMatches: [],
      address: patient.address,
      selectedInsurance: patient.insurancedata[0], // Default to first insurance
      note: "",
    };
    const fullInput = messages.filter(m => m.sender === "User").map(m => m.text.toLowerCase()).join(" ");
    const category = referralData.serviceCategories?.find(c => fullInput.includes(c.name.toLowerCase()) || fullInput.includes("physial"));
    let aiResponse = "";

    if (category) {
      tempReferralData = {
        ...tempReferralData,
        selectedCategories: [category],
        selectLocationValue: category.isFacility === "0" ? "1" : "3",
      };
      if (fullInput.includes("at home")) {
        tempReferralData.selectLocationValue = "1";
      } else if (fullInput.includes("at facil")) {
        tempReferralData.selectLocationValue = "3";
      }
      const dateMatch = fullInput.match(/((\d{1,2}\s+(january|february|march|april|may|june|july|august|september|october|november|december))|((january|february|march|april|may|june|july|august|september|october|november|december)\s+\d{1,2})|tomorrow)/i);
      if (dateMatch) {
        let formattedDate;
        if (dateMatch[0].toLowerCase() === "tomorrow") {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          formattedDate = tomorrow.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }).replace(/\//g, "-");
        } else {
          let day, month;
          if (/\d{1,2}\s/.test(dateMatch[0])) {
            [day, month] = dateMatch[0].split(/\s+/);
          } else {
            [month, day] = dateMatch[0].split(/\s+/);
          }
          const monthMap = {
            january: "01", february: "02", march: "03", april: "04", may: "05", june: "06",
            july: "07", august: "08", september: "09", october: "10", november: "11", december: "12"
          };
          formattedDate = `${monthMap[month.toLowerCase()]}-${day.padStart(2, "0")}-2025`;
          tempReferralData.schedule_date = formattedDate; // Set date here too
        }
      }
      const insuranceMatch = fullInput.match(/use insurance\s+(.+)$/i);
      if (insuranceMatch && tempReferralData.patient.insurancedata) {
        const insuranceInput = insuranceMatch[1].trim();
        const insuranceOptions = tempReferralData.patient.insurancedata;
        const selectedByName = insuranceOptions.find(ins => ins.ins_com.toLowerCase().includes(insuranceInput.toLowerCase()));
        const selectedByIndex = !isNaN(insuranceInput) && insuranceOptions[parseInt(insuranceInput) - 1];
        if (selectedByName) {
          tempReferralData.selectedInsurance = selectedByName;
        } else if (selectedByIndex) {
          tempReferralData.selectedInsurance = selectedByIndex;
        } else {
          aiResponse = `I couldn’t match "${insuranceInput}" to an insurance. Options are:\n${insuranceOptions.map((ins, i) => `${i + 1}. ${ins.ins_com} (ID: ${ins.id})`).join("\n")}\nPlease specify by name or number (e.g., "use insurance 2").`;
        }
      }
      const noteMatch = fullInput.match(/(note is|message is)\s+(.+)$/i);
      if (noteMatch) {
        tempReferralData.note = noteMatch[2].trim();
      }
    }

    setReferralData(tempReferralData);

    if (
      tempReferralData.patient &&
      tempReferralData.selectedCategories?.length &&
      tempReferralData.selectLocationValue &&
      tempReferralData.schedule_date
    ) {
      aiResponse = `Here’s the summary of the referral:\n- Patient: ${tempReferralData.patient.f_name} ${tempReferralData.patient.l_name}\n- Service: ${tempReferralData.selectedCategories[0].name}\n- Location: ${tempReferralData.selectLocationValue === "1" ? "Home" : "Facility"}\n- Date: ${tempReferralData.schedule_date}\n- Insurance: ${tempReferralData.selectedInsurance?.ins_com || "None"} (ID: ${tempReferralData.selectedInsurance?.id || "None"})\n- Note: ${tempReferralData.note || "None"}\nShall I submit this?`;
    } else if (category) {
      aiResponse = `I’ve set ${patient.f_name} ${patient.l_name} (DOB: ${patient.dob}, Phone: ${patient.phone}) for ${category.name} at ${tempReferralData.selectLocationValue === "1" ? "home" : "a facility"} with insurance ${tempReferralData.selectedInsurance?.ins_com || "None"} (ID: ${tempReferralData.selectedInsurance?.id || "None"}). When should this be scheduled? I need it in MM-DD-YYYY format (e.g., 03-30-2025).`;
    } else {
      aiResponse = `I’ve set ${patient.f_name} ${patient.l_name} (DOB: ${patient.dob}, Phone: ${patient.phone}) for the referral with insurance ${tempReferralData.selectedInsurance?.ins_com || "None"} (ID: ${tempReferralData.selectedInsurance?.id || "None"}). What service do they need (e.g., Physical Therapy)?`;
    }

    setMessages((prev) => [...prev, { sender: "AI", text: aiResponse }]);
  };

  /** Handle user input */
  const handleInput = async (userInput) => {
    setMessages((prev) => [...prev, { sender: "User", text: userInput }]);
    setLoading(true);

    if (referralData.patientMatches?.length > 0) {
      const index = parseInt(userInput) - 1;
      if (index >= 0 && index < referralData.patientMatches.length) {
        handlePatientSelection(index);
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "AI", text: `That’s not a valid number. Please pick a number between 1 and ${referralData.patientMatches.length} or click on a patient.` },
        ]);
      }
      setLoading(false);
      return;
    }

    if (referralData.patient && userInput.toLowerCase().startsWith("use insurance")) {
      const insuranceInput = userInput.replace(/use insurance\s+/i, "").trim();
      const insuranceOptions = referralData.patient.insurancedata;
      const selectedByName = insuranceOptions.find(ins => ins.ins_com.toLowerCase().includes(insuranceInput.toLowerCase()));
      const selectedByIndex = !isNaN(insuranceInput) && insuranceOptions[parseInt(insuranceInput) - 1];
      if (selectedByName) {
        setReferralData((prev) => ({ ...prev, selectedInsurance: selectedByName }));
        setMessages((prev) => [
          ...prev,
          { sender: "AI", text: `Insurance updated to ${selectedByName.ins_com} (ID: ${selectedByName.id}) for ${referralData.patient.f_name} ${referralData.patient.l_name}. Anything else to change, or shall I submit the referral?` },
        ]);
      } else if (selectedByIndex) {
        setReferralData((prev) => ({ ...prev, selectedInsurance: selectedByIndex }));
        setMessages((prev) => [
          ...prev,
          { sender: "AI", text: `Insurance updated to ${selectedByIndex.ins_com} (ID: ${selectedByIndex.id}) for ${referralData.patient.f_name} ${referralData.patient.l_name}. Anything else to change, or shall I submit the referral?` },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "AI", text: `I couldn’t match "${insuranceInput}" to an insurance for ${referralData.patient.f_name} ${referralData.patient.l_name}. Options are:\n${insuranceOptions.map((ins, i) => `${i + 1}. ${ins.ins_com} (ID: ${ins.id})`).join("\n")}\nPlease specify by name or number (e.g., "use insurance 2").` },
        ]);
      }
      setLoading(false);
      return;
    }

    const aiResponse = await callChatGPT(userInput);
    setMessages((prev) => [...prev, { sender: "AI", text: aiResponse }]);
    setLoading(false);
  };

  const sendMessage = () => {
    if (!input.trim()) return;
    handleInput(input);
    setInput("");
  };

  return (
          <CaseMangerWrapper>
    
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "100px auto",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          height: "400px",
          overflowY: "auto",
          marginBottom: "20px",
          padding: "15px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          backgroundColor: "#fafafa",
        }}
      >
        {messages.map((msg, idx) => (
          <div
            key={idx}
            style={{
              marginBottom: "15px",
              textAlign: msg.sender === "AI" ? "left" : "right",
            }}
          >
            {msg.sender === "AI" && referralData.patientMatches?.length > 0 && idx === messages.length - 1 ? (
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "15px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                }}
              >
                <p style={{ margin: "0 0 10px 0", fontSize: "14px", color: "#333" }}>
                  {msg.text.split("\n")[0]}
                </p>
                {referralData.patientMatches.map((patient, i) => (
                  <div
                    key={i}
                    onClick={() => handlePatientSelection(i)}
                    style={{
                      padding: "10px",
                      margin: "5px 0",
                      backgroundColor: "#ffffff",
                      border: "1px solid #ddd",
                      borderRadius: "6px",
                      cursor: "pointer",
                      transition: "background-color 0.2s",
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f5f5f5")}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#ffffff")}
                  >
                    <strong style={{ fontSize: "14px", color: "#333" }}>
                      {i + 1}. {patient.f_name} {patient.l_name}
                    </strong>
                    <div style={{ fontSize: "12px", color: "#666" }}>DOB: {patient.dob}</div>
                    <div style={{ fontSize: "12px", color: "#666" }}>Phone: {patient.phone}</div>
                    <div style={{ fontSize: "12px", color: "#666" }}>
                      Insurance: {patient.insurancedata.map(ins => `${ins.ins_com} (ID: ${ins.id})`).join(", ")}
                    </div>
                    <div style={{ fontSize: "12px", color: "#666" }}>
                      Address: ${patient.address?.address || "undefined"}
                    </div>
                  </div>
                ))}
                <p style={{ margin: "10px 0 0 0", fontSize: "14px", color: "#333" }}>
                  {msg.text.split("\n").slice(-1)[0]}
                </p>
              </div>
            ) : (
              <span
                style={{
                  display: "inline-block",
                  padding: "10px 15px",
                  borderRadius: "20px",
                  backgroundColor: msg.sender === "AI" ? "#e6e6e6" : "#007bff",
                  color: msg.sender === "AI" ? "#333" : "#fff",
                  fontSize: "14px",
                  maxWidth: "70%",
                  wordWrap: "break-word",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              >
                {msg.text}
              </span>
            )}
          </div>
        ))}
        {loading && (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <Spin tip="Processing..." />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onPressEnter={sendMessage}
          placeholder="Type your message or number..."
          disabled={loading}
          style={{
            flex: "1",
            marginRight: "10px",
            padding: "10px",
            borderRadius: "6px",
            border: "1px solid #ccc",
            fontSize: "14px",
            boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.05)",
          }}
        />
        <Button
          type="primary"
          onClick={sendMessage}
          disabled={loading}
          style={{
            padding: "10px 20px",
            borderRadius: "6px",
            backgroundColor: "#007bff",
            border: "none",
            color: "#fff",
            fontSize: "14px",
            cursor: loading ? "not-allowed" : "pointer",
            transition: "background-color 0.2s",
          }}
          onMouseOver={(e) => !loading && (e.currentTarget.style.backgroundColor = "#0056b3")}
          onMouseOut={(e) => !loading && (e.currentTarget.style.backgroundColor = "#007bff")}
        >
          Send
        </Button>
      </div>
    </div>
    </CaseMangerWrapper>

  );
};

export default ReferralChat;